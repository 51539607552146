.home-section-6
	position: relative
	.img-wrap
		margin-top: r(28px)
		+mq-min(lg)
			margin-top: 0
			width: 1152 / 1917 * 100%
			margin-left: auto
			figure
				aspect-ratio: 1152 / 619
	.content-wrap
		+mq-min(lg)
			position: absolute
			left: 0
			+center(Y)
			width: 100%
		.content
			+mq-min(lg)
				padding-right: r(40px)