.career-section-1
	position: relative
	z-index: 1
	+mq-min(lg)
		&:before
			+pseudo
			aspect-ratio: 168 / 148
			content: url(../img/career-bg-1.png)
			left: 0
			bottom: r(70px)
			z-index: -1
	+mq-max(lg)
		padding: r(40px) 0 0
	.img-wrap
		+mq-min(lg)
			.img
				figure
					height: r(494px)
					img
						+fit-cover
						object-position: left
	.content-wrap
		+mq-min(lg)
			position: absolute
			left: 0
			width: 100%
			+center(Y)
		+mq-max(lg)
			margin-bottom: r(20px)

.career-section-2
	position: relative
	background-image: url(../img/career-bg-2.png)
	+text(white)
	+mq-max(lg)
		padding: r(40px) 0
	+mq-min(lg)
		height: r(560px)
		display: flex
		align-items: center
	.content-wrap
		margin-bottom: r(20px)
		+mq-min(lg)
			margin-bottom: 0
			width: 100%
	.img-wrap
		+mq-min(lg)
			position: absolute
			left: 5%
			width: 35.625%
			top: r(-60px)
			bottom: r(-60px)
			.container
				max-width: none
				padding: 0

.career-section-3
	position: relative
	padding: r(40px) 0
	+mq-min(lg)
		margin-top: r(164px)
		min-height: r(740px)
		padding: r(100px) 0
	.img-wrap
		+mq-min(lg)
			position: absolute
			right: 5%
			width: 30.625%
			top: r(-84px)
		.img
			figure
				aspect-ratio: 588 / 730
				+mq-min(lg)
					height: r(730px)
					width: 100%
				img
					+fit-cover
	.content-wrap
		margin-bottom: r(20px)
		+mq-min(lg)
			margin-bottom: 0
			width: 100%

.career-list
	overflow-x: auto
	counter-reset: section
	table
		width: 100%
		border-radius: r(8px)
		overflow: hidden
		+mq-max(md)
			width: 150vw
		thead
			th
				padding: r(12px) r(16px)
				+bg(main)
				+text(white)
				text-align: center
				+font(700,20px,28px)
		tbody
			border: 1px solid color(grey-ccc)
			tr
				td
					+fz-lh(16px,24px)
					padding: r(12px) r(16px)
					text-align: center
					border-left: 1px solid color(grey-f5)
					.num
						&::before
							counter-increment: section
							content: counter(section)
				+even
					background: #F8F8F8
				&:hover
					td
						+text(blue)