.project-filter
	+flex-gap(r(60px))
	flex-wrap: wrap
	+mq-max(lg)
		gap: r(12px)
	> *
		flex: 1
		+mq-max(lg)
			+flex-width(calc(100% - 0.75rem))

.project-filter-item
	display: flex
	align-items: center
	+mq-max(lg)
		flex-direction: column
		align-items: flex-start
		gap: 0.5rem
	.title
		white-space: nowrap
		margin-right: 1rem
		+font(700,16px,24px)
		+mq-max(lg)
			flex: 1
	.filter-select
		width: 100%
		+mq-min(lg)
			flex: 1
		
.project-item
	display: block
	position: relative
	.project-img
		position: relative
		&::before
			+pseudo
			+fill
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.4))
			z-index: 2
		figure
			aspect-ratio: 390 / 205
			img
				+fit-cover
	.project-title 
		+mq-min(lg)
			position: absolute
			bottom: 0
			left: 0
			width: 100%
			padding: r(12px) r(16px)
			+text(white)
			+font(700,18px,24px)
			text-align: center
			text-transform: uppercase
			z-index: 2
		+mq-max(lg)
			text-align: center
			+font(700,16px,24px)
			margin-top: r(8px)