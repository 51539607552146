.subnav
	ul
		+flex-gap(r(20px))
		white-space: nowrap
		overflow-x: auto
		li
			a
				display: inline-flex
				padding: r(12px) r(32px)
				border: 1px solid color(main)
				+flex-center
				+text(main)
				+radius(r(4px))
				+fz-lh(18px,24px)
				+trans-all
				+parent-state(":hover",".active")
					+bg(main)
					+text(white)
				+mq-min(lg)
					min-width: r(235px)
		&.is-center
			+mq-min(lg)
				justify-content: center