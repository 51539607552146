.btn
	display: inline-flex
	+flex-center
	+reset-link
	padding: r(12px) r(24px)
	height: r(44px)
	font-weight: 700
	text-transform: uppercase
	cursor: pointer
	transition: 0.3s all
	gap: r(12px)
	font-size: r(14px)
	&-pill
		border-radius: 1000px
	
$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)
    
@each $color, $value in $colors
	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, color(body), color(white))
		+on-hover
			background-color: darken($value, 10%)

	.btn-border-#{$color}
		border: 1px solid $value
		color: $value
		+on-hover
			background-color: $value
			color: contrast-color($value, color(body), color(white))

// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.swiper-btn
	display: inline-flex
	+flex-center
	+circle(r(40px))
	+bg(grey-f5)
	font-size: 1rem
	position: absolute
	+center(Y)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	+remove(lg,max)
	+mq-min(lg)
		margin: 0 r(30px)
		&.swiper-prev
			right: 100%
		&.swiper-next
			left: 100%
	&-white
		border-color: #fff
		+text(white)

	+on-hover
		+bg(main)
		+text(white)