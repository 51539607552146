.project-detail-images
	.img
		figure
			aspect-ratio: 1230 / 552
			img
				+fit-cover

.project-detail-thumbnail
	margin-top: r(30px)
	+mq-max(lg)
		margin-top: r(15px)
	.img
		figure
			aspect-ratio: 284 / 166
			img
				+fit-cover

.project-detail-brief
	table
		width: 100%
		+fz-lh(18px,24px)
		td
			padding: 1rem 0
			border-top: 1px solid color(grey-ccc)
			+first(1)
				font-weight: 700
				+text(main)
				padding-right: r(32px)
				+mq-max(lg)
					width: 35%

.project-detail-video
	overflow: hidden
	.swiper-container
		overflow: visible
	.swiper-slide
		position: relative
		
		&.swiper-slide-active
			z-index: 2
		&.swiper-slide-prev
			.video
				transform: translateX(60%) scale(0.80)
		&.swiper-slide-next
			.video
				transform: translateX(-60%) scale(0.80)
	.video
		display: block
		transition: 0.3s all
		position: relative
		+bg(black)
		&:before
			+pseudo
			+center(both)
			+icon('\f144')
			z-index: 3
			+text(white)
			font-size: r(64px)
			font-weight: 900
			+mq-max(lg)
				font-size: r(32px)
		figure
			aspect-ratio: 1230 / 600
			opacity: 0.6
			img
				+fit-cover