.news-item
	+radius(r(8px))
	overflow: hidden
	box-shadow: $shadow-sp
	display: flex
	flex-direction: column
	+bg(white)
	+on-hover
		.news-title,.news-view-more
			+text(main)
	.news-date
		+flex-gap(r(8px))
		+fz-lh(14px,20px)
		align-items: center
		+text(grey-666)
		+mq-max(lg)
			+font(700,12px,16px)
	.news-caption
		+flex-gap-col(r(12px))
	.news-title
		+font(700,18px,24px)
		+line(3)
		+trans-all
		+mq-max(lg)
			+font(700,14px,20px)
	.news-view-more
		+fz-lh(14px,20px)
		+text(grey-666)
		+trans-all
		+mq-max(lg)
			+font(700,12px,16px)
	.news-img
		position: relative
		.icon
			position: absolute
			+center(both)
			font-size: r(40px)
			+text(white)
		figure
			img
				+fit-cover
	&.is-big
		.news-img
			figure
				aspect-ratio: 600 / 348
			.icon
				font-size: r(60px)
		.news-caption
			padding: r(28px) r(32px)
			+mq-max(md)
				padding: r(16px)
		.news-title
			+font(700,20px,28px)
		.news-brief
			+fz-lh(14px,20px)
	&.is-row
		flex-direction: row
		.news-img
			+flex-width((284 / 598 * 100%))
			+mq-max(lg)
				+flex-width(33.33%)
			figure
				height: 100%
				a
					display: block
					+img-scale(168 / 284 * 100%)
		.news-caption
			flex: 1 1 auto
			gap: r(8px)
			padding: r(16px) r(24px)
			+mq-max(md)
				padding: r(16px)
	&.is-small
		+mq-min(lg)
			flex-direction: column
			.news-img
				+flex-width(none)
				figure
					aspect-ratio: 600 / 348
			.news-caption
				padding: r(24px)
				flex: 1 1 0%
				+mq-max(md)
					padding: r(16px)
			.news-title 
				+mq-min(md)
					margin-bottom: r(12px)
			.news-view-more
				margin-top: auto