.career-detail-top
	border: 1px solid color(grey-f5)

.career-detail-brief
	padding: r(16px) 0
	+mq-max(lg)
		padding: r(8px) 0
	table
		width: 100%
		tr
			td
				padding: r(16px) 0
				width: 50%
				+mq-max(lg)
					padding: r(8px) 0
				+first(1)
					+text(main)
					+font(700,16px,24px)

.career-detail-other
	border: 1px solid color(grey-f5)
	border-radius: r(8px)
	overflow: hidden
	h2
		padding: r(12px) r(20px)
		+bg(main)
		+text(white)
		+font(700,20px,24px)
		text-transform: uppercase
	ul
		padding: r(12px) r(32px)
		+mq-max(lg)
			padding: 1rem
		li
			.other-item
				.title
					+font(700,16px,24px)
					+text(main)
					margin-bottom: 0.5rem
				.date
					+flex-gap(r(12px))
					align-items: center
					+fz-lh(14px,20px)
					+text(grey-666)
			+after-first(1)
				+has-divider-top(r(16px), color(grey-f5))

.recruitment-detail-page
	.career-section-4
		padding: 0 !important

.career-iframe-container
	margin-top: r(40px)
	iframe
		width: 100%
		height: r(702px)

.apply-frm
	border: 1px solid #ccc
	border-radius: r(10px)
	overflow: hidden
	.label
		display: none
	h4
		padding: r(12px) r(20px)
		+bg(main)
		+text(white)
		text-transform: uppercase
		+font(700,20px,28px)
		text-align: center
	.wrap-form
		padding: r(26px) r(74px)
		+mq-max(md)
			padding: r(24px)
	.frm-btnwrap
		margin-top: 0 !important
	.attachfile1
		.label
			display: flex
			align-items: center
			height: r(50px)
			padding: 0 2rem
			+bg(grey-f5)
			+radius(r(5px))
			+text(body)
			cursor: pointer
		.ruBrowse
			display: none
		.ruInputs
			li
				margin-bottom: 0
				height: auto
		.ruFileWrap
			height: auto