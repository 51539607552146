.fixed-stuff
	position: fixed
	z-index: 50
	bottom: r(55px)
	right: r(20px)
	ul
		li
			+after-first(1)
				margin-top: r(5px)
			.btn-circle
				+trans-all
				+circle(r(48px))
				display: flex
				+flex-center
				background: #00406D
				border: 2px solid #FFFFFF
				font-size: r(18px)
			&.has-tooltip
				position: relative
				.btn-circle
					position: relative
					z-index: 2
				.tooltip
					position: absolute
					right: 0
					height: r(40px)
					+center(Y)
					white-space: nowrap
					display: flex
					align-items: center
					justify-content: center
					flex-direction: column
					background: #00406D
					border: 1px solid #FFFFFF
					+text(white)
					border-radius: 999px
					z-index: 1
					padding: 0
					overflow: hidden
					opacity: 0
					pointer-events: none
					+trans-all
					+fz-lh(18px,24px)

				&:hover
					.tooltip
						opacity: 1
						pointer-events: all
						padding: 0 r(80px) 0 r(30px)