.report-heading
	display: flex
	align-items: center
	justify-content: space-between

.report-filter
	+fz-lh(16px, 24px)
	.current
		padding: r(12px) r(16px)
		border: 1px solid color(grey-f5)
		+radius(r(4px))
		+text(grey-666)
		min-width: r(120px)
		display: flex
		justify-content: space-between
		align-items: center
	.dropdown-content
		min-width: auto
		width: 100%
		ul
			padding: r(12px) r(16px)

.report-item
	min-height: r(168px)
	padding: r(28px) r(32px)
	display: flex
	flex-direction: column
	box-shadow: $shadow-sp
	+radius(r(8px))
	+mq-max(lg)
		padding: r(16px) r(20px)
	.report-item-date 
		+fz-lh(14px,20px)
		+text(grey-999)
	.report-item-title
		+fz-lh(16px,24px)
		margin-bottom: r(16px)
		+parent-state(":hover")
			text-decoration: underline
			+text(main)
	.report-item-icon
		margin-top: auto
		+fz-lh(14px,20px)
		+text(grey-666)
		+flex-gap(r(8px))
		align-items: center
