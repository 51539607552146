.field-item
	overflow: hidden
	+after-first(1)
		margin-top: r(24px)
	+mq-min(lg)
		+after-first(1)
			margin-top: r(60px)
	+odd
		.field-content-wrap
			+bg(grey-f5)
			&:before
				right: 100%
				+bg(grey-f5)
			.btn
				@extend .btn-main
		.field-img
			figure
				+mq-min(lg)
					border-radius: r(100px) 0 0 0

	+even
		.field-content-wrap
			+bg(main)
			+text(white)
			&:before
				left: 100%
				+bg(main)
			.btn
				@extend .btn-white
		.field-img
			figure
				+mq-min(lg)
					border-radius: 0 r(100px) 0 0

	.field-content-wrap
		+mq-max(lg)
			padding: 0 r(20px)
		+mq-min(lg)
			height: r(650px)
			position: relative
			&:before
				+pseudo
				width: 1000px
				top: 0
				height: 100%
	.field-title
		+mq-min(lg)
			padding: 0 0 0 r(40px)
	.field-img-wrap
		+mq-min(lg)
			.container
				max-width: none
				padding: 0
	.field-img
		position: relative
		+mq-min(lg)
			margin-top: r(-460px)
			z-index: 2
		figure
			overflow: hidden
			aspect-ratio: 1575 / 650
			img
				+fit-cover
	.field-brief
		+line(2)