.home-section-2
	.item
		display: block
		position: relative
		&:before
			+pseudo
			bottom: 0
			left: 0
			width: 100%
			height: r(360px)
			background: linear-gradient(180deg, rgba(24, 73, 145, 0) 0%, rgba(24, 73, 145, 0.23) 29.19%, #184991 87.58%)
			z-index: 1
			opacity: 0
			+trans-all
			+context(".item",".item:hover")
				opacity: 1
		.img
			figure
				aspect-ratio: 480 / 720
				+bg(black)
				img
					opacity: 0.7
					+fit-cover
		.title
			position: absolute
			bottom: r(64px)
			left: 0
			+text(white)
			width: 100%
			text-align: center
			+font(700,30px,40px)
			padding: 0 r(16px)
			z-index: 2
			+mq-max(lg)
				bottom: r(20px)
				font-size: r(24px)