@charset "UTF-8";
/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Text color opacity -------------------------*/
/* Background color -------------------------*/
/* Background color opacity -------------------------*/
/* Background image -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Has Divider -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Image Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Image Contain -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
/* Order item -------------------------*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *:before, *:after {
  outline: none;
  box-sizing: border-box; }

img, video, iframe {
  max-width: 100%; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

a {
  color: inherit; }

strong, b {
  font-weight: 700; }

input, select, textarea, button {
  font-family: inherit; }

button {
  cursor: pointer; }

/* ==========================================================================
    GRIDLEX
    Just a Flexbox Grid System - v. 2.7.1
========================================================================== */
.row {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-left: -1.875rem;
  margin-bottom: -1.875rem; }

.row > * {
  width: 100%; }

.row > * {
  box-sizing: border-box;
  padding: 0 0 1.875rem 1.875rem; }

.col {
  flex: 1 1 0%; }

@media screen and (max-width: 1024px) {
  .row {
    margin-left: -1.5rem;
    margin-bottom: -1.5rem; }
  .row > * {
    padding: 0 0 1.5rem 1.5rem; } }

/************************
    HELPERS SUFFIXES
*************************/
.row.no-gutter {
  margin: 0; }
  .row.no-gutter > * {
    padding: 0; }

.row.equal-height > * {
  align-self: stretch; }
  .row.equal-height > * > * {
    height: 100%; }

/************************
    GRID BY NUMBER
*************************/
.row.row-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row.row-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row.row-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row.row-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row.row-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row.row-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.row.row-7 > * {
  flex: 0 0 14.28571%;
  max-width: 14.28571%; }

.row.row-8 > * {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.row.row-9 > * {
  flex: 0 0 11.11111%;
  max-width: 11.11111%; }

.row.row-10 > * {
  flex: 0 0 10%;
  max-width: 10%; }

.row.row-11 > * {
  flex: 0 0 9.09091%;
  max-width: 9.09091%; }

.row.row-12 > * {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

@media (min-width: 576px) {
  .row.row-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-sm-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-sm-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-sm-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-sm-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-sm-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-sm-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 768.98px) {
  .row.row-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-md-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-md-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-md-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-md-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-md-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-md-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1024.98px) {
  .row.row-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-lg-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-lg-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-lg-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-lg-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-lg-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-lg-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1280px) {
  .row.row-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-xl-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-xl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-xl-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-xl-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-xl-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-xl-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.off-0 {
  margin-left: 0; }

.off-1 {
  margin-left: 8.33333%; }

.off-2 {
  margin-left: 16.66667%; }

.off-3 {
  margin-left: 25%; }

.off-4 {
  margin-left: 33.33333%; }

.off-5 {
  margin-left: 41.66667%; }

.off-6 {
  margin-left: 50%; }

.off-7 {
  margin-left: 58.33333%; }

.off-8 {
  margin-left: 66.66667%; }

.off-9 {
  margin-left: 75%; }

.off-10 {
  margin-left: 83.33333%; }

.off-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768.98px) {
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024.98px) {
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

body, html {
  line-height: 1;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #333;
  font-size: 3.6vw; }
  @media (min-width: 769px) {
    body, html {
      font-size: 1.5vw; } }
  @media (min-width: 1025px) {
    body, html {
      font-size: 12px; } }
  @media (min-width: 1281px) {
    body, html {
      font-size: 16px; } }

*, *:before, *:after {
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

.fa-exclamation-triangle {
  line-height: 2.5;
  color: #ff3333;
  font-weight: 400;
  font-family: 'Plus Jakarta Sans', sans-serif; }
  .fa-exclamation-triangle:before {
    font-family: 'Font Awesome 5 Pro'; }

.frm-btn-reset {
  display: none !important; }

.frm-btnwrap .label {
  display: none; }

.fb-like span {
  height: 24px !important; }
  .fb-like span iframe {
    height: 24px !important; }

.fixed-stuff {
  position: fixed;
  z-index: 50;
  bottom: 3.4375rem;
  right: 1.25rem; }
  .fixed-stuff ul li:nth-child(n + 2) {
    margin-top: 0.3125rem; }
  .fixed-stuff ul li .btn-circle {
    transition: all 0.3s ease-in-out;
    width: 3rem;
    height: 3rem;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00406D;
    border: 2px solid #FFFFFF;
    font-size: 1.125rem; }
  .fixed-stuff ul li.has-tooltip {
    position: relative; }
    .fixed-stuff ul li.has-tooltip .btn-circle {
      position: relative;
      z-index: 2; }
    .fixed-stuff ul li.has-tooltip .tooltip {
      position: absolute;
      right: 0;
      height: 2.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #00406D;
      border: 1px solid #FFFFFF;
      color: #fff;
      border-radius: 999px;
      z-index: 1;
      padding: 0;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      font-size: 1.125rem;
      line-height: 1.33333; }
    .fixed-stuff ul li.has-tooltip:hover .tooltip {
      opacity: 1;
      pointer-events: all;
      padding: 0 5rem 0 1.875rem; }

.global-footer {
  padding: 5rem 0 3rem;
  background-image: url(../img/footer-bg.jpg);
  color: #fff; }
  @media (max-width: 1024px) {
    .global-footer {
      padding: 2.5rem 0 2.5rem; } }

.footer-link h3 {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.33333;
  margin-bottom: 1.25rem;
  text-transform: uppercase; }

.footer-link ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; }
  .footer-link ul li a {
    font-size: 1rem;
    line-height: 1.5; }

.footer-bottom {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1); }
  @media (max-width: 1024px) {
    .footer-bottom {
      margin-top: 2rem;
      padding-top: 2rem; } }

.footer-info h3 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 1.5rem; }

.footer-info ul {
  display: flex;
  flex-direction: column;
  gap: 1rem; }
  .footer-info ul li {
    display: flex;
    align-items: center;
    display: flex;
    gap: 1rem; }
    .footer-info ul li span {
      width: 2.75rem;
      height: 2.75rem;
      border-radius: 999999px;
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: #fff;
      color: #142D65;
      font-size: 1.25rem; }
    .footer-info ul li p {
      flex: 1;
      font-size: 1rem;
      line-height: 1.5; }

.footer-copyright {
  font-size: 0.875rem;
  line-height: 1.28571;
  display: flex;
  flex-direction: column;
  gap: 0.75rem; }

.footer-subscription .wrap-form h3 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.33333;
  margin-bottom: 1rem; }

.footer-subscription .wrap-form p {
  font-size: 1rem;
  line-height: 1.5; }

.footer-subscription .wrap-form .form-note {
  margin-bottom: 1.25rem; }

.footer-subscription .wrap-form .form-group textarea {
  height: 6rem; }

.footer-subscription .wrap-form .form-group input[type="text"], .footer-subscription .wrap-form .form-group input[type="date"], .footer-subscription .wrap-form .form-group select, .footer-subscription .wrap-form .form-group textarea {
  ckground: #fff; }

.footer-subscription .wrap-form .frm-btn input[type="submit"] {
  background-color: #00ADDC; }

.global-header {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  background-color: #fff; }

.header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 1024px) {
    .header-wrap {
      height: 4rem; } }

@media (max-width: 1024px) {
  .logo {
    height: 3.75rem; }
    .logo img {
      height: 3.75rem; } }

.header-right {
  display: flex;
  gap: 1.75rem;
  align-items: center; }

.header-util-wrapper {
  display: flex;
  gap: 0.75rem;
  align-items: center; }

@media (min-width: 1025px) {
  .menu > ul {
    display: flex;
    gap: 1.75rem; } }

@media (max-width: 1024px) {
  .menu > ul > li:nth-child(n + 2) {
    margin-top: 6vw; } }

.menu > ul > li > a {
  display: inline-flex;
  min-height: 5.5rem;
  align-items: center;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.33333;
  position: relative; }
  @media (min-width: 1025px) {
    .menu > ul > li.active > a {
      color: #142D65; } }
  .menu > ul > li > a:before {
    content: "";
    display: block;
    position: absolute;
    height: 0.25rem;
    width: 100%;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background-clip: padding-box;
    bottom: 0;
    left: 0;
    display: none;
    background-color: #fff; }
    @media (min-width: 1025px) {
      .menu > ul > li > a:before {
        background-color: #142D65; } }
    .menu > ul > li.active > a:before {
      display: block; }
  @media (max-width: 1024px) {
    .menu > ul > li > a {
      font-size: 6.4vw;
      min-height: auto;
      padding-bottom: 3.6vw;
      text-align: left;
      justify-content: flex-start;
      color: #fff; } }

.has-sub {
  position: relative;
  display: flex;
  align-items: center; }
  .has-sub > span {
    margin-left: 5px; }
  .has-sub .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2; }
    @media (min-width: 1025px) {
      .has-sub .sub-menu {
        min-width: 15rem; } }
    .has-sub .sub-menu ul {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      background: #fff; }
      .has-sub .sub-menu ul li a {
        white-space: nowrap;
        display: flex;
        align-items: center;
        font-size: 1rem;
        padding: 0.75rem 1.5rem;
        transition: all 0.3s ease-in-out; }
      .has-sub .sub-menu ul li:hover a, .has-sub .sub-menu ul li.active a {
        background-color: #142D65;
        color: #fff; }
  @media (max-width: 1024px) {
    .has-sub {
      justify-content: space-between;
      flex-wrap: wrap; }
      .has-sub > span {
        font-size: 4vw;
        color: #fff;
        position: relative;
        top: -1vw; }
      .has-sub .sub-menu {
        position: static;
        width: 100%;
        border-top: 0;
        box-shadow: none; }
        .has-sub .sub-menu ul {
          background: transparent;
          background: #fff;
          color: #333; }
          .has-sub .sub-menu ul li a {
            font-size: 2.4vw;
            white-space: initial;
            padding: 2vw 3.6vw; }
          .has-sub .sub-menu ul li.active a {
            margin-right: 0;
            border-radius: 0; } }
  @media (min-width: 1025px) {
    .has-sub .toggle {
      display: none; }
    .has-sub:hover .sub-menu {
      display: block; } }

.search-link a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.33333;
  color: #142D65; }

.header-util-divider {
  height: 1.25rem;
  width: 1px;
  background-color: #f5f5f5; }

.language .current {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5; }
  .language .current em {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.14286; }

.language .dropdown-content {
  z-index: 2;
  top: 100%;
  min-width: auto; }
  .language .dropdown-content ul {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.42857; }

.menu-toggle {
  display: none; }
  @media (max-width: 1024px) {
    .menu-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      font-size: 1.5rem;
      line-height: 1;
      background-color: #142D65;
      color: #fff; } }

.mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: none;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px); }
  .mobile-menu .mobile-menu-overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%; }
  .mobile-menu .mobile-menu-wrapper {
    z-index: 2;
    height: 100%;
    width: 100%;
    position: relative;
    transform: translateY(-100%);
    transition: 0.3s all;
    pointer-events: none; }
    .mobile-menu.is-open .mobile-menu-wrapper {
      transform: none; }
  .mobile-menu .mobile-menu-container {
    position: absolute;
    pointer-events: all;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 88vh;
    width: 94vw;
    background: linear-gradient(180deg, #211261 50%, #2e1493 100%);
    padding: 8vw 6vw;
    overflow: auto; }
  .mobile-menu .logo-w {
    margin-bottom: 8vw; }

.loading-area {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: hidden; }

.loading-box {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 9999; }

.loading-pic {
  width: 100%;
  position: absolute;
  top: 50%;
  z-index: 99999;
  text-align: center;
  transform: translateY(-50%); }

.loader {
  height: 58px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  width: 58px; }
  .loader span {
    background: #142d65;
    display: block;
    height: 12px;
    opacity: 0;
    position: absolute;
    width: 12px;
    animation: load 4.6s ease-in-out infinite; }

.loader span.block-1 {
  animation-delay: 1.06s;
  left: 0px;
  top: 0px; }

.loader span.block-2 {
  animation-delay: 0.97s;
  left: 16px;
  top: 0px; }

.loader span.block-3 {
  animation-delay: 0.87s;
  left: 31px;
  top: 0px; }

.loader span.block-4 {
  animation-delay: 0.78s;
  left: 47px;
  top: 0px; }

.loader span.block-5 {
  animation-delay: 0.69s;
  left: 0px;
  top: 16px; }

.loader span.block-6 {
  animation-delay: 0.6s;
  left: 16px;
  top: 16px; }

.loader span.block-7 {
  animation-delay: 0.51s;
  left: 31px;
  top: 16px; }

.loader span.block-8 {
  animation-delay: 0.41s;
  left: 47px;
  top: 16px; }

.loader span.block-9 {
  animation-delay: 0.32s;
  left: 0px;
  top: 31px; }

.loader span.block-10 {
  animation-delay: 0.23s;
  left: 16px;
  top: 31px; }

.loader span.block-11 {
  animation-delay: 0.14s;
  left: 31px;
  top: 31px; }

.loader span.block-12 {
  animation-delay: 0.05s;
  left: 47px;
  top: 31px; }

.loader span.block-13 {
  animation-delay: -0.05s;
  left: 0px;
  top: 47px; }

.loader span.block-14 {
  animation-delay: -0.14s;
  left: 16px;
  top: 47px; }

.loader span.block-15 {
  animation-delay: -0.23s;
  left: 31px;
  top: 47px; }

.loader span.block-16 {
  animation-delay: -0.32s;
  left: 47px;
  top: 47px; }

@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(-97px); }
  15% {
    opacity: 0;
    transform: translateY(-97px); }
  30% {
    opacity: 1;
    transform: translateY(0); }
  70% {
    opacity: 1;
    transform: translateY(0); }
  85% {
    opacity: 0;
    transform: translateY(97px); }
  100% {
    opacity: 0;
    transform: translateY(97px); } }

.search-page {
  padding: 3.75rem 0; }
  @media (max-width: 1024px) {
    .search-page {
      padding: 2.5rem 0; } }
  .search-page h1 {
    margin-bottom: 1.25rem;
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 700;
    color: #333333; }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f002';
      font-family: "Font Awesome 6 Pro";
      top: 0;
      right: 0.625rem;
      z-index: 1;
      font-size: 1.5rem;
      color: #142D65;
      width: 3.75rem;
      height: 3.75rem;
      display: flex;
      align-items: center;
      justify-content: center; }
  .search-page .searchcontrols .frm-btn, .search-page .searchcontrols input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0.625rem;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2;
    width: 3.75rem;
    height: 3.75rem;
    min-width: auto; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 1.25rem;
    padding-right: 3.375rem;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 3.75rem !important;
    border-radius: 1.875rem !important;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    outline: none;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 1.25rem;
    font-size: 0.875rem;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 1.875rem; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 1.875rem; }
  .search-page .searchresult {
    margin-bottom: 1.875rem;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 1rem;
    border-radius: 0.5rem; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 1.125rem;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 0.625rem; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 0.625rem; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.AspNet-TreeView {
  line-height: 1.5; }
  .AspNet-TreeView > ul {
    columns: 3 auto;
    margin: 10px 0 0;
    padding-bottom: 25px; }
    .AspNet-TreeView > ul > .AspNet-TreeView-Root {
      margin-bottom: 26px;
      break-inside: avoid-column; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root > a {
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 7px; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
        display: none; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li {
        padding-bottom: 7px;
        font-size: 18px;
        color: #06c; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li > a {
          font-weight: 500;
          display: inline-block; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > a {
          margin-bottom: 7px; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul {
          list-style: disc;
          padding-left: 1.25em; }
          .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul > li {
            font-size: 16px;
            margin-bottom: 7px; }
  @media screen and (max-width: 768.98px) {
    .AspNet-TreeView > ul {
      column-count: 1; } }

.sitemap {
  padding: 2.5rem 0; }
  .sitemap a:hover {
    text-decoration: underline; }

.sitemap-heading {
  height: 3.25rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-bottom: 2rem; }
  .sitemap-heading h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.5; }

.sub-banner {
  overflow: hidden; }
  .sub-banner .banner {
    position: relative; }
    .sub-banner .banner .caption {
      position: absolute;
      bottom: 5rem;
      left: 0;
      width: 100%;
      color: #fff;
      font-size: 2.5rem;
      line-height: 1.3; }
      @media (max-width: 1024px) {
        .sub-banner .banner .caption {
          bottom: 1.5rem;
          font-size: 1.25rem;
          line-height: 1.4; } }
    @media (max-width: 1024px) {
      .sub-banner .banner .img figure {
        aspect-ratio: 16 / 9; }
        .sub-banner .banner .img figure img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }

.news-detail-page .sub-banner, .product-detail-page .sub-banner, .recruitment-detail-page .sub-banner {
  display: none; }

.breadcrumb-wrapper {
  background-color: #fff;
  color: #999; }
  @media (max-width: 1024px) {
    .breadcrumb-wrapper {
      display: none; } }
  .breadcrumb-wrapper .breadcrumb {
    padding: 0.5rem 0;
    display: flex;
    align-items: center; }
    .breadcrumb-wrapper .breadcrumb li a {
      font-size: 0.875rem;
      line-height: 1.28571;
      display: flex;
      align-items: center; }
    .breadcrumb-wrapper .breadcrumb li + li {
      padding-left: 0.75rem;
      display: flex;
      align-items: center; }
      .breadcrumb-wrapper .breadcrumb li + li::before {
        content: "\f054";
        font-family: 'Font Awesome 6 Pro';
        margin-right: 0.75rem;
        font-weight: 400;
        font-size: 0.875rem;
        position: relative;
        font-weight: 300; }
    .breadcrumb-wrapper .breadcrumb li:first-child a {
      font-size: 0; }
      .breadcrumb-wrapper .breadcrumb li:first-child a:before {
        content: '\f015';
        font-family: 'Font Awesome 6 Pro';
        font-size: 1rem;
        line-height: 1.125;
        font-weight: 300; }

.about-nav-wrap {
  background-color: #fff; }

.about-nav {
  margin-top: 2.5rem; }
  @media (max-width: 1024px) {
    .about-nav {
      margin-top: 1.25rem; } }
  .about-nav ul {
    display: flex;
    gap: 2.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #ccc; }
    @media (min-width: 1025px) {
      .about-nav ul {
        justify-content: center; } }
    @media (max-width: 1024px) {
      .about-nav ul {
        white-space: nowrap;
        overflow-x: auto; } }
    .about-nav ul li a {
      font-size: 0.875rem;
      line-height: 1.42857;
      position: relative;
      display: inline-flex;
      transition: all 0.3s ease-in-out; }
      .about-nav ul li:hover a {
        color: #142D65; }
        .about-nav ul li:hover a:before {
          opacity: 1; }
      .about-nav ul li.active a {
        color: #142D65; }
        .about-nav ul li.active a:before {
          opacity: 1; }
      .about-nav ul li a:before {
        content: "";
        display: block;
        position: absolute;
        transition: all 0.3s ease-in-out;
        bottom: -0.8125rem;
        height: 1px;
        width: 100%;
        background-color: #142D65;
        opacity: 0; }

.about-section-1 {
  position: relative;
  z-index: 1; }
  @media (min-width: 1025px) {
    .about-section-1 {
      overflow: hidden; }
      .about-section-1:before {
        content: "";
        display: block;
        position: absolute;
        aspect-ratio: 168 / 147;
        content: url(../img/about-bg-1.png);
        left: 0;
        bottom: -420px;
        z-index: -1; } }
  @media (min-width: 1025px) {
    .about-section-1 .img-wrap .img figure {
      height: 34.5625rem; }
      .about-section-1 .img-wrap .img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left; } }
  @media (min-width: 1025px) {
    .about-section-1 .content-wrap {
      position: absolute;
      left: 0;
      width: 100%;
      top: 3.75rem; } }
  @media (max-width: 1024px) {
    .about-section-1 .content-wrap {
      margin-bottom: 1.25rem; } }

.about-section-2 {
  position: relative;
  z-index: 1; }
  @media (max-width: 1024px) {
    .about-section-2 {
      padding: 2.5rem 0 0; } }
  @media (min-width: 1025px) {
    .about-section-2 {
      overflow: hidden; }
      .about-section-2:before {
        content: "";
        display: block;
        position: absolute;
        aspect-ratio: 168 / 147;
        content: url(../img/about-bg-2.png);
        left: 0;
        bottom: 56px;
        z-index: -1; } }
  @media (min-width: 1025px) {
    .about-section-2 .content-wrap {
      position: absolute;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%; } }
  @media (max-width: 1024px) {
    .about-section-2 .content-wrap {
      margin-bottom: 3rem; } }
  @media (min-width: 1025px) {
    .about-section-2 .img-wrap .img figure {
      height: 36.4375rem; }
      .about-section-2 .img-wrap .img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left; } }
  .about-section-2 .list-item {
    margin-top: 3rem;
    padding-left: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: -4rem; }
    .about-section-2 .list-item > * {
      padding-left: 4rem; }
    @media (max-width: 1024px) {
      .about-section-2 .list-item {
        margin-left: -6rem;
        justify-content: center; } }
    .about-section-2 .list-item .title {
      position: relative;
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      transform: rotate(45deg);
      width: 7.375rem;
      height: 7.375rem;
      display: flex;
      align-items: center;
      justify-content: center; }
      .about-section-2 .list-item .title span {
        display: block;
        transform: rotate(-45deg);
        position: absolute;
        text-align: center;
        font-size: 1.125rem;
        line-height: 1.33333; }

@media (min-width: 1025px) {
  .about-section-3 .section-content {
    height: 37rem;
    display: flex; }
    .about-section-3 .section-content > * {
      width: 100%; }
      .about-section-3 .section-content > * .row {
        height: 100%; } }

.about-section-3 .item-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #142D65;
  color: #fff; }
  @media (min-width: 1025px) {
    .about-section-3 .item-wrap {
      transform: skew(-10deg); } }
  .about-section-3 .item-wrap .item {
    text-align: center;
    padding: 0 3.75rem; }
    @media (min-width: 1025px) {
      .about-section-3 .item-wrap .item {
        transform: skew(10deg); } }
    @media (max-width: 1024px) {
      .about-section-3 .item-wrap .item {
        padding: 1.875rem 1.5rem; } }
    .about-section-3 .item-wrap .item .icon {
      margin-bottom: 1.75rem; }
    .about-section-3 .item-wrap .item .title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.33333;
      margin-bottom: 0.75rem; }

@media (max-width: 1024px) {
  .about-section-3 .item-col:nth-child(n + 2) {
    margin-top: 1.25rem; } }

@media (min-width: 1025px) {
  .about-section-3 .item-col:nth-child(n + 2) .item-wrap {
    margin-left: -0.3125rem; } }

@media (min-width: 1025px) {
  .about-section-3 .item-col:nth-child(2n) {
    padding-top: 3.75rem; } }

.about-section-3 .item-col:nth-child(2n) .item-wrap {
  background-color: rgba(0, 173, 220, 0.8); }

@media (min-width: 1025px) {
  .about-section-3 .item-col:nth-child(3n) {
    padding-top: 7.5rem; } }

.about-section-3 .item-col:nth-child(3n) .item-wrap {
  background-color: #fff;
  color: #333; }
  .about-section-3 .item-col:nth-child(3n) .item-wrap .item .title {
    color: #142D65; }

.staff-list-wrap:nth-child(n + 2) {
  margin-top: 2.5rem; }

.staff-list .staff-item .img {
  position: relative;
  z-index: 1; }
  .staff-list .staff-item .img .bg {
    position: absolute;
    top: 7.5%;
    left: 0;
    z-index: -1;
    opacity: 0.18; }
  .staff-list .staff-item .img figure {
    aspect-ratio: 2 / 3; }
    .staff-list .staff-item .img figure img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom; }

.staff-list .staff-item .caption {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 2rem;
  background-color: #fff;
  color: #142D65;
  line-height: 1.5; }
  @media (max-width: 768px) {
    .staff-list .staff-item .caption {
      padding: 0.75rem 1rem; } }

.about-section-5 {
  position: relative; }
  .about-section-5 .bg-left {
    position: absolute;
    top: 0;
    left: 0; }
  .about-section-5 .bg-right {
    position: absolute;
    bottom: 0;
    right: 0; }
  .about-section-5 .bg-item {
    z-index: -1; }
    .about-section-5 .bg-item img {
      width: 10vw; }

@media (min-width: 1025px) {
  .about-section-6 {
    min-height: 26.8125rem; } }

.customer-slider .swiper-container {
  padding: 0.625rem 0;
  margin: -0.625rem 0; }

.partner-item {
  transition: all 0.3s ease-in-out; }
  @media (hover: hover) and (pointer: fine) {
    .partner-item:hover {
      transform: translateY(-10px); } }
  .partner-item figure {
    aspect-ratio: 230 / 140;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center; }
    .partner-item figure img {
      width: 90%; }

@media (min-width: 1025px) {
  .about-section-7 {
    min-height: 45.3125rem;
    display: flex;
    flex-direction: column; } }

.about-section-7 .customer-slider-wrap {
  padding: 0 0 2.5rem; }
  @media (min-width: 1025px) {
    .about-section-7 .customer-slider-wrap {
      height: 35.625rem;
      padding: 7.5rem 0;
      background-image: url("../img/about-bg-8.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; } }

.award-item .img figure {
  aspect-ratio: 285 / 407; }
  .award-item .img figure img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.award-item .title {
  font-size: 0.875rem;
  line-height: 1.42857;
  margin-top: 0.5rem;
  text-align: center; }

@media (min-width: 1025px) {
  .about-section-9 {
    min-height: 57.75rem;
    position: relative; } }

.about-section-9 .img-wrap {
  margin-top: 1.25rem; }
  @media (min-width: 1025px) {
    .about-section-9 .img-wrap {
      margin-top: 0;
      position: absolute;
      top: 1.25rem;
      right: 0;
      width: 53.2603%; } }

@media (min-width: 1025px) {
  .about-section-9 .history-slider {
    position: relative; }
    .about-section-9 .history-slider:before {
      content: "";
      display: block;
      position: absolute;
      left: 8rem;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #142D65; }
    .about-section-9 .history-slider .swiper-container {
      height: 36.25rem; } }

.about-section-9 .history-slider .swiper-vertical-btn {
  position: absolute;
  left: 1.75rem;
  font-size: 2rem;
  color: #ccc;
  cursor: pointer; }
  @media (max-width: 1024px) {
    .about-section-9 .history-slider .swiper-vertical-btn {
      display: none; } }
  .about-section-9 .history-slider .swiper-vertical-btn.swiper-prev {
    bottom: 100%; }
  .about-section-9 .history-slider .swiper-vertical-btn.swiper-next {
    top: 100%; }

.about-section-9 .history-slider .history-item {
  display: flex;
  padding: 1rem 0 1.875rem; }
  @media (max-width: 1024px) {
    .about-section-9 .history-slider .history-item {
      flex-direction: column;
      padding: 0 1.5rem 0 0; } }
  .about-section-9 .history-slider .history-item .year {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1.2;
    color: #142D65; }
    @media (min-width: 1025px) {
      .about-section-9 .history-slider .history-item .year {
        max-width: 7.125rem;
        flex: 0 0 7.125rem;
        width: 100%; } }
    @media (max-width: 1024px) {
      .about-section-9 .history-slider .history-item .year {
        font-size: 1.75rem; } }
  .about-section-9 .history-slider .history-item .content-wrap {
    padding-left: 2.5rem;
    display: flex;
    gap: 1.25rem; }
    @media (max-width: 1024px) {
      .about-section-9 .history-slider .history-item .content-wrap {
        padding-left: 0; } }
    .about-section-9 .history-slider .history-item .content-wrap .fas {
      color: #142D65;
      font-size: 1.25rem;
      line-height: 1.75rem;
      position: relative;
      top: 0.5rem; }
      @media (max-width: 1024px) {
        .about-section-9 .history-slider .history-item .content-wrap .fas {
          display: none; } }

.contact-info {
  padding: 3.25rem 2.25rem;
  background-color: #142D65;
  color: #fff;
  border-radius: 0.75rem; }
  @media (max-width: 1024px) {
    .contact-info {
      padding: 1.5rem; } }
  .contact-info h2 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.375;
    margin-bottom: 1.5rem; }
  .contact-info img {
    width: 100%;
    margin-bottom: 1.5rem; }
  .contact-info ul {
    display: flex;
    flex-direction: column;
    gap: 1rem; }
    .contact-info ul li {
      display: flex;
      align-items: center;
      display: flex;
      gap: 1rem; }
      .contact-info ul li:nth-child(n + 2) {
        padding-top: 1rem;
        border-top: 1px solid rgba(255, 255, 255, 0.2); }
      .contact-info ul li span {
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 999999px;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #fff;
        color: #142D65;
        font-size: 1.25rem; }
      .contact-info ul li p {
        flex: 1;
        font-size: 1rem;
        line-height: 1.5; }

@media (min-width: 1025px) {
  .contact-form {
    padding: 2.25rem 0; } }

@media (max-width: 1024px) {
  .contact-form {
    margin-top: 1.875rem;
    border-radius: 0.5rem;
    overflow: hidden; } }

.contact-form .form-title {
  padding: 0.75rem 2.5rem 0.75rem 3.75rem;
  background-color: #142D65;
  color: #fff;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.4;
  text-transform: uppercase; }
  @media (max-width: 1024px) {
    .contact-form .form-title {
      padding: 0.75rem 1.5rem; } }
  @media (min-width: 1025px) {
    .contact-form .form-title {
      border-radius: 0px 0.5rem 0 0px; } }

.contact-form .wrap-form {
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  overflow: hidden;
  padding: 1.5rem 2.5rem 1.5rem 3.75rem; }
  @media (min-width: 1025px) {
    .contact-form .wrap-form {
      border-radius: 0px 0 0.5rem 0px; } }
  @media (max-width: 1024px) {
    .contact-form .wrap-form {
      border-radius: 0px 0 0.5rem 0.5rem;
      padding: 1rem 1.5rem;
      border: 1px solid #CCCCCC; } }
  .contact-form .wrap-form .row {
    margin-left: -1.25rem;
    margin-bottom: -1.25rem; }
    .contact-form .wrap-form .row > * {
      padding-left: 1.25rem;
      padding-bottom: 1.25rem; }

.contact-map iframe {
  height: 37.5rem;
  width: 100%; }

.subnav ul {
  display: flex;
  gap: 1.25rem;
  white-space: nowrap;
  overflow-x: auto; }
  .subnav ul li a {
    display: inline-flex;
    padding: 0.75rem 2rem;
    border: 1px solid #142D65;
    align-items: center;
    justify-content: center;
    color: #142D65;
    border-radius: 0.25rem;
    background-clip: padding-box;
    font-size: 1.125rem;
    line-height: 1.33333;
    transition: all 0.3s ease-in-out; }
    .subnav ul li:hover a {
      background-color: #142D65;
      color: #fff; }
    .subnav ul li.active a {
      background-color: #142D65;
      color: #fff; }
    @media (min-width: 1025px) {
      .subnav ul li a {
        min-width: 14.6875rem; } }
  @media (min-width: 1025px) {
    .subnav ul.is-center {
      justify-content: center; } }

.career-detail-top {
  border: 1px solid #f5f5f5; }

.career-detail-brief {
  padding: 1rem 0; }
  @media (max-width: 1024px) {
    .career-detail-brief {
      padding: 0.5rem 0; } }
  .career-detail-brief table {
    width: 100%; }
    .career-detail-brief table tr td {
      padding: 1rem 0;
      width: 50%; }
      @media (max-width: 1024px) {
        .career-detail-brief table tr td {
          padding: 0.5rem 0; } }
      .career-detail-brief table tr td:first-child {
        color: #142D65;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5; }

.career-detail-other {
  border: 1px solid #f5f5f5;
  border-radius: 0.5rem;
  overflow: hidden; }
  .career-detail-other h2 {
    padding: 0.75rem 1.25rem;
    background-color: #142D65;
    color: #fff;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.2;
    text-transform: uppercase; }
  .career-detail-other ul {
    padding: 0.75rem 2rem; }
    @media (max-width: 1024px) {
      .career-detail-other ul {
        padding: 1rem; } }
    .career-detail-other ul li .other-item .title {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5;
      color: #142D65;
      margin-bottom: 0.5rem; }
    .career-detail-other ul li .other-item .date {
      display: flex;
      gap: 0.75rem;
      align-items: center;
      font-size: 0.875rem;
      line-height: 1.42857;
      color: #666; }
    .career-detail-other ul li:nth-child(n + 2) {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid #f5f5f5; }

.recruitment-detail-page .career-section-4 {
  padding: 0 !important; }

.career-iframe-container {
  margin-top: 2.5rem; }
  .career-iframe-container iframe {
    width: 100%;
    height: 43.875rem; }

.apply-frm {
  border: 1px solid #ccc;
  border-radius: 0.625rem;
  overflow: hidden; }
  .apply-frm .label {
    display: none; }
  .apply-frm h4 {
    padding: 0.75rem 1.25rem;
    background-color: #142D65;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.4;
    text-align: center; }
  .apply-frm .wrap-form {
    padding: 1.625rem 4.625rem; }
    @media (max-width: 768px) {
      .apply-frm .wrap-form {
        padding: 1.5rem; } }
  .apply-frm .frm-btnwrap {
    margin-top: 0 !important; }
  .apply-frm .attachfile1 .label {
    display: flex;
    align-items: center;
    height: 3.125rem;
    padding: 0 2rem;
    background-color: #f5f5f5;
    border-radius: 0.3125rem;
    background-clip: padding-box;
    color: #333;
    cursor: pointer; }
  .apply-frm .attachfile1 .ruBrowse {
    display: none; }
  .apply-frm .attachfile1 .ruInputs li {
    margin-bottom: 0;
    height: auto; }
  .apply-frm .attachfile1 .ruFileWrap {
    height: auto; }

.career-section-1 {
  position: relative;
  z-index: 1; }
  @media (min-width: 1025px) {
    .career-section-1:before {
      content: "";
      display: block;
      position: absolute;
      aspect-ratio: 168 / 148;
      content: url(../img/career-bg-1.png);
      left: 0;
      bottom: 4.375rem;
      z-index: -1; } }
  @media (max-width: 1024px) {
    .career-section-1 {
      padding: 2.5rem 0 0; } }
  @media (min-width: 1025px) {
    .career-section-1 .img-wrap .img figure {
      height: 30.875rem; }
      .career-section-1 .img-wrap .img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left; } }
  @media (min-width: 1025px) {
    .career-section-1 .content-wrap {
      position: absolute;
      left: 0;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); } }
  @media (max-width: 1024px) {
    .career-section-1 .content-wrap {
      margin-bottom: 1.25rem; } }

.career-section-2 {
  position: relative;
  background-image: url(../img/career-bg-2.png);
  color: #fff; }
  @media (max-width: 1024px) {
    .career-section-2 {
      padding: 2.5rem 0; } }
  @media (min-width: 1025px) {
    .career-section-2 {
      height: 35rem;
      display: flex;
      align-items: center; } }
  .career-section-2 .content-wrap {
    margin-bottom: 1.25rem; }
    @media (min-width: 1025px) {
      .career-section-2 .content-wrap {
        margin-bottom: 0;
        width: 100%; } }
  @media (min-width: 1025px) {
    .career-section-2 .img-wrap {
      position: absolute;
      left: 5%;
      width: 35.625%;
      top: -3.75rem;
      bottom: -3.75rem; }
      .career-section-2 .img-wrap .container {
        max-width: none;
        padding: 0; } }

.career-section-3 {
  position: relative;
  padding: 2.5rem 0; }
  @media (min-width: 1025px) {
    .career-section-3 {
      margin-top: 10.25rem;
      min-height: 46.25rem;
      padding: 6.25rem 0; } }
  @media (min-width: 1025px) {
    .career-section-3 .img-wrap {
      position: absolute;
      right: 5%;
      width: 30.625%;
      top: -5.25rem; } }
  .career-section-3 .img-wrap .img figure {
    aspect-ratio: 588 / 730; }
    @media (min-width: 1025px) {
      .career-section-3 .img-wrap .img figure {
        height: 45.625rem;
        width: 100%; } }
    .career-section-3 .img-wrap .img figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .career-section-3 .content-wrap {
    margin-bottom: 1.25rem; }
    @media (min-width: 1025px) {
      .career-section-3 .content-wrap {
        margin-bottom: 0;
        width: 100%; } }

.career-list {
  overflow-x: auto;
  counter-reset: section; }
  .career-list table {
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden; }
    @media (max-width: 768px) {
      .career-list table {
        width: 150vw; } }
    .career-list table thead th {
      padding: 0.75rem 1rem;
      background-color: #142D65;
      color: #fff;
      text-align: center;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.4; }
    .career-list table tbody {
      border: 1px solid #ccc; }
      .career-list table tbody tr td {
        font-size: 1rem;
        line-height: 1.5;
        padding: 0.75rem 1rem;
        text-align: center;
        border-left: 1px solid #f5f5f5; }
        .career-list table tbody tr td .num::before {
          counter-increment: section;
          content: counter(section); }
      .career-list table tbody tr:nth-child(even) {
        background: #F8F8F8; }
      .career-list table tbody tr:hover td {
        color: #00ADDC; }

.field-detail-feature figure {
  aspect-ratio: 390 / 240; }
  .field-detail-feature figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.field-detail-feature-slider {
  overflow: hidden; }
  .field-detail-feature-slider .swiper-container {
    overflow: visible; }
  .field-detail-feature-slider .swiper-slide .field-detail-project {
    position: relative; }
    @media (min-width: 1025px) {
      .field-detail-feature-slider .swiper-slide .field-detail-project:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        background: linear-gradient(180deg, rgba(4, 5, 11, 0) 35.71%, rgba(35, 48, 100, 0.74) 70%);
        width: 100%;
        left: 0;
        height: 15.5rem;
        opacity: 0;
        transition: 0.3s all;
        pointer-events: none; }
        .field-detail-feature-slider .swiper-slide-active .field-detail-project:before {
          opacity: 1; } }
    .field-detail-feature-slider .swiper-slide .field-detail-project .img figure {
      aspect-ratio: 1230 / 600; }
      .field-detail-feature-slider .swiper-slide .field-detail-project .img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .field-detail-feature-slider .swiper-slide .field-detail-project .caption {
      text-align: center;
      padding: 0 1rem 2rem;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.4;
      opacity: 0;
      transition: 0.3s all; }
      @media (max-width: 1024px) {
        .field-detail-feature-slider .swiper-slide .field-detail-project .caption {
          margin-top: 1rem;
          font-size: 0.875rem; } }
      @media (min-width: 1025px) {
        .field-detail-feature-slider .swiper-slide .field-detail-project .caption {
          position: absolute;
          width: 100%;
          bottom: 0;
          color: #fff;
          pointer-events: none; } }
      .field-detail-feature-slider .swiper-slide-active .field-detail-project .caption {
        opacity: 1; }

.field-item {
  overflow: hidden; }
  .field-item:nth-child(n + 2) {
    margin-top: 1.5rem; }
  @media (min-width: 1025px) {
    .field-item:nth-child(n + 2) {
      margin-top: 3.75rem; } }
  .field-item:nth-child(odd) .field-content-wrap {
    background-color: #f5f5f5; }
    .field-item:nth-child(odd) .field-content-wrap:before {
      right: 100%;
      background-color: #f5f5f5; }
  @media (min-width: 1025px) {
    .field-item:nth-child(odd) .field-img figure {
      border-radius: 6.25rem 0 0 0; } }
  .field-item:nth-child(even) .field-content-wrap {
    background-color: #142D65;
    color: #fff; }
    .field-item:nth-child(even) .field-content-wrap:before {
      left: 100%;
      background-color: #142D65; }
  @media (min-width: 1025px) {
    .field-item:nth-child(even) .field-img figure {
      border-radius: 0 6.25rem 0 0; } }
  @media (max-width: 1024px) {
    .field-item .field-content-wrap {
      padding: 0 1.25rem; } }
  @media (min-width: 1025px) {
    .field-item .field-content-wrap {
      height: 40.625rem;
      position: relative; }
      .field-item .field-content-wrap:before {
        content: "";
        display: block;
        position: absolute;
        width: 1000px;
        top: 0;
        height: 100%; } }
  @media (min-width: 1025px) {
    .field-item .field-title {
      padding: 0 0 0 2.5rem; } }
  @media (min-width: 1025px) {
    .field-item .field-img-wrap .container {
      max-width: none;
      padding: 0; } }
  .field-item .field-img {
    position: relative; }
    @media (min-width: 1025px) {
      .field-item .field-img {
        margin-top: -28.75rem;
        z-index: 2; } }
    .field-item .field-img figure {
      overflow: hidden;
      aspect-ratio: 1575 / 650; }
      .field-item .field-img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .field-item .field-brief {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }

.home-banner-slider .swiper-pagination {
  bottom: 3.75rem; }
  @media (max-width: 1024px) {
    .home-banner-slider .swiper-pagination {
      bottom: 1.25rem; } }
  .home-banner-slider .swiper-pagination .swiper-pagination-bullet {
    border: 1px solid #fff;
    opacity: 1;
    background: transparent;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 10px;
    transition: 0.3s all; }
    .home-banner-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #fff;
      width: 1.25rem; }

.home-banner-slider .banner-item {
  position: relative; }
  .home-banner-slider .banner-item .banner-caption {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 8.75rem;
    color: #fff;
    font-size: 2.5rem;
    line-height: 1.333333;
    font-weight: 300; }
    @media (max-width: 1024px) {
      .home-banner-slider .banner-item .banner-caption {
        bottom: 1.5rem;
        font-size: 1.5rem; } }
  @media (max-width: 768px) {
    .home-banner-slider .banner-item .banner-img figure {
      padding-top: 133.333%;
      display: block;
      position: relative; }
      .home-banner-slider .banner-item .banner-img figure img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; } }

.home-section-1 {
  color: #fff; }
  @media (min-width: 1025px) {
    .home-section-1 {
      min-height: 67.0625rem;
      position: relative; } }
  @media (min-width: 1025px) {
    .home-section-1 .img-wrap {
      position: absolute;
      top: 0;
      right: 0;
      width: 54.92958%; } }
  @media (min-width: 1025px) {
    .home-section-1 .content-wrap {
      min-height: 45.5rem; } }
  .home-section-1 .list-wrap .item {
    text-align: center; }
    .home-section-1 .list-wrap .item .icon {
      aspect-ratio: 1;
      width: 3.5rem;
      margin: 0 auto 0.75rem; }
      .home-section-1 .list-wrap .item .icon img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .home-section-1 .list-wrap .item .number {
      font-weight: 700;
      font-size: 3.5rem;
      line-height: 1.35714; }
    .home-section-1 .list-wrap .item .text {
      margin-top: 0.5rem;
      line-height: 1.5; }

.home-section-3 .section-heading {
  gap: 0.5rem; }
  @media (max-width: 768px) {
    .home-section-3 .section-heading {
      flex-direction: column; } }

.home-section-2 .item {
  display: block;
  position: relative; }
  .home-section-2 .item:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 22.5rem;
    background: linear-gradient(180deg, rgba(24, 73, 145, 0) 0%, rgba(24, 73, 145, 0.23) 29.19%, #184991 87.58%);
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease-in-out; }
    .home-section-2 .item:hover:before {
      opacity: 1; }
  .home-section-2 .item .img figure {
    aspect-ratio: 480 / 720;
    background-color: #000; }
    .home-section-2 .item .img figure img {
      opacity: 0.7;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .home-section-2 .item .title {
    position: absolute;
    bottom: 4rem;
    left: 0;
    color: #fff;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 1.33333;
    padding: 0 1rem;
    z-index: 2; }
    @media (max-width: 1024px) {
      .home-section-2 .item .title {
        bottom: 1.25rem;
        font-size: 1.5rem; } }

.home-section-6 {
  position: relative; }
  .home-section-6 .img-wrap {
    margin-top: 1.75rem; }
    @media (min-width: 1025px) {
      .home-section-6 .img-wrap {
        margin-top: 0;
        width: 60.0939%;
        margin-left: auto; }
        .home-section-6 .img-wrap figure {
          aspect-ratio: 1152 / 619; } }
  @media (min-width: 1025px) {
    .home-section-6 .content-wrap {
      position: absolute;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%; } }
  @media (min-width: 1025px) {
    .home-section-6 .content-wrap .content {
      padding-right: 2.5rem; } }

.project-detail-images .img figure {
  aspect-ratio: 1230 / 552; }
  .project-detail-images .img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.project-detail-thumbnail {
  margin-top: 1.875rem; }
  @media (max-width: 1024px) {
    .project-detail-thumbnail {
      margin-top: 0.9375rem; } }
  .project-detail-thumbnail .img figure {
    aspect-ratio: 284 / 166; }
    .project-detail-thumbnail .img figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.project-detail-brief table {
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.33333; }
  .project-detail-brief table td {
    padding: 1rem 0;
    border-top: 1px solid #ccc; }
    .project-detail-brief table td:first-child {
      font-weight: 700;
      color: #142D65;
      padding-right: 2rem; }
      @media (max-width: 1024px) {
        .project-detail-brief table td:first-child {
          width: 35%; } }

.project-detail-video {
  overflow: hidden; }
  .project-detail-video .swiper-container {
    overflow: visible; }
  .project-detail-video .swiper-slide {
    position: relative; }
    .project-detail-video .swiper-slide.swiper-slide-active {
      z-index: 2; }
    .project-detail-video .swiper-slide.swiper-slide-prev .video {
      transform: translateX(60%) scale(0.8); }
    .project-detail-video .swiper-slide.swiper-slide-next .video {
      transform: translateX(-60%) scale(0.8); }
  .project-detail-video .video {
    display: block;
    transition: 0.3s all;
    position: relative;
    background-color: #000; }
    .project-detail-video .video:before {
      content: "";
      display: block;
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "";
      font-family: 'Font Awesome 6 Pro';
      z-index: 3;
      color: #fff;
      font-size: 4rem;
      font-weight: 900; }
      @media (max-width: 1024px) {
        .project-detail-video .video:before {
          font-size: 2rem; } }
    .project-detail-video .video figure {
      aspect-ratio: 1230 / 600;
      opacity: 0.6; }
      .project-detail-video .video figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; }

.project-filter {
  display: flex;
  gap: 3.75rem;
  flex-wrap: wrap; }
  @media (max-width: 1024px) {
    .project-filter {
      gap: 0.75rem; } }
  .project-filter > * {
    flex: 1; }
    @media (max-width: 1024px) {
      .project-filter > * {
        max-width: calc(100% - 0.75rem);
        flex: 0 0 calc(100% - 0.75rem);
        width: 100%; } }

.project-filter-item {
  display: flex;
  align-items: center; }
  @media (max-width: 1024px) {
    .project-filter-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem; } }
  .project-filter-item .title {
    white-space: nowrap;
    margin-right: 1rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5; }
    @media (max-width: 1024px) {
      .project-filter-item .title {
        flex: 1; } }
  .project-filter-item .filter-select {
    width: 100%; }
    @media (min-width: 1025px) {
      .project-filter-item .filter-select {
        flex: 1; } }

.project-item {
  display: block;
  position: relative; }
  .project-item .project-img {
    position: relative; }
    .project-item .project-img::before {
      content: "";
      display: block;
      position: absolute;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.4));
      z-index: 2; }
    .project-item .project-img figure {
      aspect-ratio: 390 / 205; }
      .project-item .project-img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  @media (min-width: 1025px) {
    .project-item .project-title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0.75rem 1rem;
      color: #fff;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.33333;
      text-align: center;
      text-transform: uppercase;
      z-index: 2; } }
  @media (max-width: 1024px) {
    .project-item .project-title {
      text-align: center;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5;
      margin-top: 0.5rem; } }

.news-item {
  border-radius: 0.5rem;
  background-clip: padding-box;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  background-color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .news-item:hover .news-title, .news-item:hover .news-view-more {
      color: #142D65; } }
  .news-item .news-date {
    display: flex;
    gap: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.42857;
    align-items: center;
    color: #666; }
    @media (max-width: 1024px) {
      .news-item .news-date {
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.33333; } }
  .news-item .news-caption {
    display: flex;
    flex-direction: column;
    gap: 0.75rem; }
  .news-item .news-title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.33333;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 1024px) {
      .news-item .news-title {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.42857; } }
  .news-item .news-view-more {
    font-size: 0.875rem;
    line-height: 1.42857;
    color: #666;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 1024px) {
      .news-item .news-view-more {
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.33333; } }
  .news-item .news-img {
    position: relative; }
    .news-item .news-img .icon {
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5rem;
      color: #fff; }
    .news-item .news-img figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .news-item.is-big .news-img figure {
    aspect-ratio: 600 / 348; }
  .news-item.is-big .news-img .icon {
    font-size: 3.75rem; }
  .news-item.is-big .news-caption {
    padding: 1.75rem 2rem; }
    @media (max-width: 768px) {
      .news-item.is-big .news-caption {
        padding: 1rem; } }
  .news-item.is-big .news-title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.4; }
  .news-item.is-big .news-brief {
    font-size: 0.875rem;
    line-height: 1.42857; }
  .news-item.is-row {
    flex-direction: row; }
    .news-item.is-row .news-img {
      max-width: 47.49164%;
      flex: 0 0 47.49164%;
      width: 100%; }
      @media (max-width: 1024px) {
        .news-item.is-row .news-img {
          max-width: 33.33%;
          flex: 0 0 33.33%;
          width: 100%; } }
      .news-item.is-row .news-img figure {
        height: 100%; }
        .news-item.is-row .news-img figure a {
          display: block;
          padding-top: 59.15493%;
          display: block;
          position: relative; }
          .news-item.is-row .news-img figure a img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover; }
    .news-item.is-row .news-caption {
      flex: 1 1 auto;
      gap: 0.5rem;
      padding: 1rem 1.5rem; }
      @media (max-width: 768px) {
        .news-item.is-row .news-caption {
          padding: 1rem; } }
  @media (min-width: 1025px) {
    .news-item.is-small {
      flex-direction: column; }
      .news-item.is-small .news-img {
        max-width: none;
        flex: 0 0 none;
        width: 100%; }
        .news-item.is-small .news-img figure {
          aspect-ratio: 600 / 348; }
      .news-item.is-small .news-caption {
        padding: 1.5rem;
        flex: 1 1 0%; } }
    @media (min-width: 1025px) and (max-width: 768px) {
      .news-item.is-small .news-caption {
        padding: 1rem; } }
  @media (min-width: 1025px) and (min-width: 769px) {
    .news-item.is-small .news-title {
      margin-bottom: 0.75rem; } }
  @media (min-width: 1025px) {
      .news-item.is-small .news-view-more {
        margin-top: auto; } }

.news-detail-title {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.33333; }

.detail-meta {
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #ccc; }

.detail-date {
  font-size: 0.875rem;
  line-height: 1.42857;
  color: #666; }

.shareholder-list-wrap:nth-child(even) {
  background-color: #f5f5f5; }
  .shareholder-list-wrap:nth-child(even) .row {
    flex-direction: row-reverse; }

.report-table-wrap {
  border-radius: 0.5rem;
  overflow: hidden; }

.report-table table {
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff; }
  .report-table table th, .report-table table td {
    padding: 0.75rem 1.25rem; }
  .report-table table th {
    background-color: #142D65;
    color: #fff;
    text-transform: uppercase;
    white-space: nowrap;
    height: 3.125rem; }
  .report-table table tr:hover {
    color: #142D65 !important; }
  .report-table table tr:nth-child(even) {
    background-color: #f5f5f5; }
  .report-table table tfoot td {
    height: 3rem; }
    .report-table table tfoot td a:hover {
      color: #142D65 !important; }

.report-img figure {
  height: 100%; }
  .report-img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.company-info {
  background-color: #142D65;
  color: #fff; }
  .company-info .img figure {
    aspect-ratio: 390 / 266; }
    .company-info .img figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .company-info .info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 2rem; }
    .company-info .info h3 {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.33333; }
    .company-info .info p {
      font-size: 1.125rem;
      line-height: 1.33333;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; }

.report-heading {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.report-filter {
  font-size: 1rem;
  line-height: 1.5; }
  .report-filter .current {
    padding: 0.75rem 1rem;
    border: 1px solid #f5f5f5;
    border-radius: 0.25rem;
    background-clip: padding-box;
    color: #666;
    min-width: 7.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .report-filter .dropdown-content {
    min-width: auto;
    width: 100%; }
    .report-filter .dropdown-content ul {
      padding: 0.75rem 1rem; }

.report-item {
  min-height: 10.5rem;
  padding: 1.75rem 2rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  background-clip: padding-box; }
  @media (max-width: 1024px) {
    .report-item {
      padding: 1rem 1.25rem; } }
  .report-item .report-item-date {
    font-size: 0.875rem;
    line-height: 1.42857;
    color: #999; }
  .report-item .report-item-title {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem; }
    .report-item:hover .report-item-title {
      text-decoration: underline;
      color: #142D65; }
  .report-item .report-item-icon {
    margin-top: auto;
    font-size: 0.875rem;
    line-height: 1.42857;
    color: #666;
    display: flex;
    gap: 0.5rem;
    align-items: center; }

.article-content {
  font-size: 1rem;
  line-height: 1.5; }
  .article-content > * + * {
    margin-top: 1rem; }
  .article-content ul {
    list-style: disc;
    padding-left: 1.25rem; }
  .article-content table td, .article-content table th {
    border: 1px solid #dee2e6;
    padding: .75rem;
    vertical-align: top; }
  .article-content h2 {
    font-size: 1.25rem;
    font-weight: 700; }
  .article-content h3 {
    font-size: 1.125rem;
    font-weight: 600; }
  .article-content * + h2 {
    margin-top: 1.75rem; }

.expand-content {
  overflow: hidden; }
  .expand-content.show {
    max-height: none !important; }

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  height: 2.75rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s all;
  gap: 0.75rem;
  font-size: 0.875rem; }
  .btn:hover, .btn:focus, .btn:active {
    text-decoration: none;
    outline: none; }
  .btn-pill {
    border-radius: 1000px; }

.btn-main, .field-item:nth-child(odd) .field-content-wrap .btn {
  background-color: #142D65;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-main:hover, .field-item:nth-child(odd) .field-content-wrap .btn:hover {
      background-color: #0c1a3a; } }

.btn-border-main {
  border: 1px solid #142D65;
  color: #142D65; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-main:hover {
      background-color: #142D65;
      color: #fff; } }

.btn-blue {
  background-color: #00ADDC;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-blue:hover {
      background-color: #0085a9; } }

.btn-border-blue {
  border: 1px solid #00ADDC;
  color: #00ADDC; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-blue:hover {
      background-color: #00ADDC;
      color: #fff; } }

.btn-main-light {
  background-color: #2E3092;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-main-light:hover {
      background-color: #22236b; } }

.btn-border-main-light {
  border: 1px solid #2E3092;
  color: #2E3092; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-main-light:hover {
      background-color: #2E3092;
      color: #fff; } }

.btn-grey-light {
  background-color: #f5f5f5;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-light:hover {
      background-color: gainsboro; } }

.btn-border-grey-light {
  border: 1px solid #f5f5f5;
  color: #f5f5f5; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-light:hover {
      background-color: #f5f5f5;
      color: #333; } }

.btn-grey-666 {
  background-color: #666;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-666:hover {
      background-color: #4d4d4d; } }

.btn-border-grey-666 {
  border: 1px solid #666;
  color: #666; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-666:hover {
      background-color: #666;
      color: #fff; } }

.btn-grey-999 {
  background-color: #999;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-999:hover {
      background-color: gray; } }

.btn-border-grey-999 {
  border: 1px solid #999;
  color: #999; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-999:hover {
      background-color: #999;
      color: #333; } }

.btn-grey-ccc {
  background-color: #ccc;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-ccc:hover {
      background-color: #b3b3b3; } }

.btn-border-grey-ccc {
  border: 1px solid #ccc;
  color: #ccc; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-ccc:hover {
      background-color: #ccc;
      color: #333; } }

.btn-grey-f5 {
  background-color: #f5f5f5;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-f5:hover {
      background-color: gainsboro; } }

.btn-border-grey-f5 {
  border: 1px solid #f5f5f5;
  color: #f5f5f5; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-f5:hover {
      background-color: #f5f5f5;
      color: #333; } }

.btn-white, .field-item:nth-child(even) .field-content-wrap .btn {
  background-color: #fff;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-white:hover, .field-item:nth-child(even) .field-content-wrap .btn:hover {
      background-color: #e6e6e6; } }

.btn-border-white {
  border: 1px solid #fff;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-white:hover {
      background-color: #fff;
      color: #333; } }

.btn-body {
  background-color: #333;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-body:hover {
      background-color: #1a1a1a; } }

.btn-border-body {
  border: 1px solid #333;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-body:hover {
      background-color: #333;
      color: #fff; } }

.btn-black {
  background-color: #000;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-black:hover {
      background-color: black; } }

.btn-border-black {
  border: 1px solid #000;
  color: #000; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-black:hover {
      background-color: #000;
      color: #fff; } }

.swiper-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999999px;
  background-color: #f5f5f5;
  font-size: 1rem;
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  transition: 0.3s all; }
  @media (max-width: 1024px) {
    .swiper-btn {
      display: none; } }
  @media (min-width: 1025px) {
    .swiper-btn {
      margin: 0 1.875rem; }
      .swiper-btn.swiper-prev {
        right: 100%; }
      .swiper-btn.swiper-next {
        left: 100%; } }
  .swiper-btn-white {
    border-color: #fff;
    color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .swiper-btn:hover {
      background-color: #142D65;
      color: #fff; } }

.container {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 769px) {
    .container {
      max-width: 92vw; } }
  @media (min-width: 1025px) {
    .container {
      max-width: 90vw; } }
  @media (min-width: 1281px) {
    .container {
      max-width: 1260px; } }

.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  right: 0;
  z-index: 1; }
  .dropdown-content > * {
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 20px; }

.dropdown:hover .dropdown-content {
  display: block; }

.fz-12 {
  font-size: 0.75rem; }

.fz-14 {
  font-size: 0.875rem; }

.fz-16 {
  font-size: 1rem; }

.fz-18 {
  font-size: 1.125rem; }

.fz-20 {
  font-size: 1.25rem; }

.fz-22 {
  font-size: 1.375rem; }

.fz-24 {
  font-size: 1.5rem; }

.fz-26 {
  font-size: 1.625rem; }

.fz-28 {
  font-size: 1.75rem; }

.fz-30 {
  font-size: 1.875rem; }

.fz-32 {
  font-size: 2rem; }

.fz-34 {
  font-size: 2.125rem; }

.fz-36 {
  font-size: 2.25rem; }

.fz-38 {
  font-size: 2.375rem; }

.fz-40 {
  font-size: 2.5rem; }

@media (min-width: 577px) {
  .fz-sm-12 {
    font-size: 0.75rem; }
  .fz-sm-14 {
    font-size: 0.875rem; }
  .fz-sm-16 {
    font-size: 1rem; }
  .fz-sm-18 {
    font-size: 1.125rem; }
  .fz-sm-20 {
    font-size: 1.25rem; }
  .fz-sm-22 {
    font-size: 1.375rem; }
  .fz-sm-24 {
    font-size: 1.5rem; }
  .fz-sm-26 {
    font-size: 1.625rem; }
  .fz-sm-28 {
    font-size: 1.75rem; }
  .fz-sm-30 {
    font-size: 1.875rem; }
  .fz-sm-32 {
    font-size: 2rem; }
  .fz-sm-34 {
    font-size: 2.125rem; }
  .fz-sm-36 {
    font-size: 2.25rem; }
  .fz-sm-38 {
    font-size: 2.375rem; }
  .fz-sm-40 {
    font-size: 2.5rem; } }

@media (min-width: 769px) {
  .fz-md-12 {
    font-size: 0.75rem; }
  .fz-md-14 {
    font-size: 0.875rem; }
  .fz-md-16 {
    font-size: 1rem; }
  .fz-md-18 {
    font-size: 1.125rem; }
  .fz-md-20 {
    font-size: 1.25rem; }
  .fz-md-22 {
    font-size: 1.375rem; }
  .fz-md-24 {
    font-size: 1.5rem; }
  .fz-md-26 {
    font-size: 1.625rem; }
  .fz-md-28 {
    font-size: 1.75rem; }
  .fz-md-30 {
    font-size: 1.875rem; }
  .fz-md-32 {
    font-size: 2rem; }
  .fz-md-34 {
    font-size: 2.125rem; }
  .fz-md-36 {
    font-size: 2.25rem; }
  .fz-md-38 {
    font-size: 2.375rem; }
  .fz-md-40 {
    font-size: 2.5rem; } }

@media (min-width: 1025px) {
  .fz-lg-12 {
    font-size: 0.75rem; }
  .fz-lg-14 {
    font-size: 0.875rem; }
  .fz-lg-16 {
    font-size: 1rem; }
  .fz-lg-18 {
    font-size: 1.125rem; }
  .fz-lg-20 {
    font-size: 1.25rem; }
  .fz-lg-22 {
    font-size: 1.375rem; }
  .fz-lg-24 {
    font-size: 1.5rem; }
  .fz-lg-26 {
    font-size: 1.625rem; }
  .fz-lg-28 {
    font-size: 1.75rem; }
  .fz-lg-30 {
    font-size: 1.875rem; }
  .fz-lg-32 {
    font-size: 2rem; }
  .fz-lg-34 {
    font-size: 2.125rem; }
  .fz-lg-36 {
    font-size: 2.25rem; }
  .fz-lg-38 {
    font-size: 2.375rem; }
  .fz-lg-40 {
    font-size: 2.5rem; } }

@media (min-width: 1281px) {
  .fz-xl-12 {
    font-size: 0.75rem; }
  .fz-xl-14 {
    font-size: 0.875rem; }
  .fz-xl-16 {
    font-size: 1rem; }
  .fz-xl-18 {
    font-size: 1.125rem; }
  .fz-xl-20 {
    font-size: 1.25rem; }
  .fz-xl-22 {
    font-size: 1.375rem; }
  .fz-xl-24 {
    font-size: 1.5rem; }
  .fz-xl-26 {
    font-size: 1.625rem; }
  .fz-xl-28 {
    font-size: 1.75rem; }
  .fz-xl-30 {
    font-size: 1.875rem; }
  .fz-xl-32 {
    font-size: 2rem; }
  .fz-xl-34 {
    font-size: 2.125rem; }
  .fz-xl-36 {
    font-size: 2.25rem; }
  .fz-xl-38 {
    font-size: 2.375rem; }
  .fz-xl-40 {
    font-size: 2.5rem; } }

.form-group input[type="text"], .form-group input[type="date"], .form-group select, .form-group textarea {
  border-radius: 0.25rem;
  background-clip: padding-box;
  background: #F5F5F5;
  border: 0;
  padding: 0.75rem 1.25rem !important;
  width: 100%;
  height: 3rem;
  font-style: normal !important;
  font-size: 1rem !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important; }

.form-group select {
  appearance: none;
  background-image: url("../img/sl.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 1.25rem) 50%; }
  .form-group select option {
    color: #333; }

.form-group textarea {
  height: 12.5rem; }

.form-group .RadPicker {
  width: 100% !important;
  position: relative; }
  .form-group .RadPicker .riTextBox {
    background-color: rgba(255, 255, 255, 0.95);
    border: 0 !important; }
  .form-group .RadPicker .riEmpty {
    color: #666; }
  .form-group .RadPicker .rcTable {
    width: 100% !important; }
  .form-group .RadPicker .rcCalPopup {
    position: absolute;
    right: 0.625rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../img/calendar.png);
    background-position: center !important; }

.form-group.qradio ul {
  margin-bottom: -1.25rem;
  display: flex;
  flex-wrap: wrap; }
  .form-group.qradio ul > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc( (100% - (2 - 1) * 1.25rem) / 2);
    margin-bottom: 1.25rem; }
    .form-group.qradio ul > *:not(:last-child) {
      margin-right: 1.25rem; }
    .form-group.qradio ul > *:nth-child(2n) {
      margin-right: 0; }
  .form-group.qradio ul li input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    white-space: nowrap; }
    .form-group.qradio ul li input:checked ~ label {
      color: #00ADDC; }
      .form-group.qradio ul li input:checked ~ label:before {
        content: '\f192'; }
  .form-group.qradio ul li label {
    display: flex; }
    .form-group.qradio ul li label:before {
      content: '\f111';
      font-family: "Font Awesome 6 Pro";
      margin-right: 0.625rem;
      position: relative; }

.frm-btnwrap {
  margin-top: 1.875rem; }
  @media (max-width: 576px) {
    .frm-btnwrap {
      margin-top: 1.25rem; } }

.frm-btn {
  position: relative;
  display: inline-block;
  margin-left: auto;
  width: 7.25rem; }
  .frm-btn:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    content: '\f178';
    font-family: "Font Awesome 6 Pro";
    color: #fff;
    pointer-events: none; }
  .frm-btn input[type="submit"] {
    transition: all 0.3s ease-in-out;
    border-radius: 1.5rem;
    background-clip: padding-box;
    background-color: #142D65;
    color: #fff;
    border: 0;
    height: 3rem;
    font-weight: 700;
    width: 100%;
    cursor: pointer;
    padding: 0 4rem 0 1.875rem;
    font-size: 1rem;
    text-transform: uppercase;
    appearance: none; }

.frm-captcha {
  display: flex;
  flex-wrap: wrap-reverse;
  margin-top: 1.25rem;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 1.25rem; }
  .frm-captcha label {
    display: none; }
  .frm-captcha .frm-captcha-input {
    flex: 1; }
    @media (max-width: 576px) {
      .frm-captcha .frm-captcha-input {
        flex: 0 0 100%; } }
  .frm-captcha .RadCaptcha {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    max-width: fit-content; }
    .frm-captcha .RadCaptcha img {
      height: 3.125rem !important; }
    .frm-captcha .RadCaptcha > span {
      color: red;
      display: block;
      margin-top: 5px; }
    .frm-captcha .RadCaptcha > div > div {
      display: flex;
      align-items: center; }
    .frm-captcha .RadCaptcha a {
      font-size: 0;
      margin-left: 20px; }
      .frm-captcha .RadCaptcha a:before {
        font-family: "Font Awesome 6 Pro";
        content: '\f2ea';
        font-size: 1.6875rem;
        color: #333; }

.form-container .wrap-form .qradio .label {
  display: none; }

.form-container .wrap-form .frm-btn {
  height: 3.125rem;
  width: 100%; }
  .form-container .wrap-form .frm-btn input[type="submit"] {
    width: 100%;
    min-width: auto; }

.form-container .wrap-form .frm-btnwrap {
  width: 7.1875rem;
  margin-top: 0 !important; }
  @media (max-width: 576px) {
    .form-container .wrap-form .frm-btnwrap {
      width: 100%; } }

.img-zoom-out img {
  transition: all 0.3s ease-in-out; }

@media (hover: hover) and (pointer: fine) {
  .img-zoom-out:hover img {
    transform: scale(1.05); } }

.img-zoom-in img {
  transition: all 0.3s ease-in-out;
  transform: scale(1.05); }

@media (hover: hover) and (pointer: fine) {
  .img-zoom-in:hover img {
    transform: scale(1); } }

.text-main {
  color: #142D65; }

.text-blue {
  color: #00ADDC; }

.text-main-light {
  color: #2E3092; }

.text-grey-light {
  color: #f5f5f5; }

.text-grey-666 {
  color: #666; }

.text-grey-999 {
  color: #999; }

.text-grey-ccc {
  color: #ccc; }

.text-grey-f5 {
  color: #f5f5f5; }

.text-white {
  color: #fff; }

.text-body {
  color: #333; }

.text-black {
  color: #000; }

.background-main {
  background: #142D65; }

.background-blue {
  background: #00ADDC; }

.background-main-light {
  background: #2E3092; }

.background-grey-light {
  background: #f5f5f5; }

.background-grey-666 {
  background: #666; }

.background-grey-999 {
  background: #999; }

.background-grey-ccc {
  background: #ccc; }

.background-grey-f5 {
  background: #f5f5f5; }

.background-white {
  background: #fff; }

.background-body {
  background: #333; }

.background-black {
  background: #000; }

/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Text color opacity -------------------------*/
/* Background color -------------------------*/
/* Background color opacity -------------------------*/
/* Background image -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Has Divider -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Image Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Image Contain -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
/* Order item -------------------------*/
.object-fit-img img {
  font-family: 'object-fit: cover'; }

.modulepager {
  margin-top: 1.875rem; }
  .modulepager .pagination {
    display: flex;
    align-items: center;
    justify-content: center; }
    .modulepager .pagination li {
      list-style: none;
      display: inline-block;
      text-align: center;
      margin-left: 0.5rem; }
      .modulepager .pagination li:first-child {
        margin-left: 0; }
      .modulepager .pagination li a, .modulepager .pagination li span {
        justify-content: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
        color: #142D65;
        border-radius: 50%;
        background-clip: padding-box;
        border: 2px solid #142D65;
        transition: all 0.3s ease-in-out; }
      .modulepager .pagination li.active a, .modulepager .pagination li.active span, .modulepager .pagination li:hover a, .modulepager .pagination li:hover span {
        background-color: #142D65;
        color: #fff; }
      .modulepager .pagination li .FirstPage, .modulepager .pagination li .LastPage, .modulepager .pagination li .BackPage, .modulepager .pagination li .NextPage {
        display: none !important; }

.no-pagination .pages {
  display: none; }

.mt-0 {
  margin-top: 0rem;
  : 0rem; }

.mt-1 {
  margin-top: 0.25rem;
  : 0.25rem; }

.mt-2 {
  margin-top: 0.5rem;
  : 0.5rem; }

.mt-3 {
  margin-top: 0.75rem;
  : 0.75rem; }

.mt-4 {
  margin-top: 1rem;
  : 1rem; }

.mt-5 {
  margin-top: 1.25rem;
  : 1.25rem; }

.mt-6 {
  margin-top: 1.5rem;
  : 1.5rem; }

.mt-7 {
  margin-top: 1.75rem;
  : 1.75rem; }

.mt-8 {
  margin-top: 2rem;
  : 2rem; }

.mt-9 {
  margin-top: 2.25rem;
  : 2.25rem; }

.mt-10 {
  margin-top: 2.5rem;
  : 2.5rem; }

.mt-11 {
  margin-top: 2.75rem;
  : 2.75rem; }

.mt-12 {
  margin-top: 3rem;
  : 3rem; }

.mt-13 {
  margin-top: 3.25rem;
  : 3.25rem; }

.mt-14 {
  margin-top: 3.5rem;
  : 3.5rem; }

.mt-15 {
  margin-top: 3.75rem;
  : 3.75rem; }

.mt-16 {
  margin-top: 4rem;
  : 4rem; }

.mt-17 {
  margin-top: 4.25rem;
  : 4.25rem; }

.mt-18 {
  margin-top: 4.5rem;
  : 4.5rem; }

.mt-19 {
  margin-top: 4.75rem;
  : 4.75rem; }

.mt-20 {
  margin-top: 5rem;
  : 5rem; }

.mr-0 {
  margin-right: 0rem;
  : 0rem; }

.mr-1 {
  margin-right: 0.25rem;
  : 0.25rem; }

.mr-2 {
  margin-right: 0.5rem;
  : 0.5rem; }

.mr-3 {
  margin-right: 0.75rem;
  : 0.75rem; }

.mr-4 {
  margin-right: 1rem;
  : 1rem; }

.mr-5 {
  margin-right: 1.25rem;
  : 1.25rem; }

.mr-6 {
  margin-right: 1.5rem;
  : 1.5rem; }

.mr-7 {
  margin-right: 1.75rem;
  : 1.75rem; }

.mr-8 {
  margin-right: 2rem;
  : 2rem; }

.mr-9 {
  margin-right: 2.25rem;
  : 2.25rem; }

.mr-10 {
  margin-right: 2.5rem;
  : 2.5rem; }

.mr-11 {
  margin-right: 2.75rem;
  : 2.75rem; }

.mr-12 {
  margin-right: 3rem;
  : 3rem; }

.mr-13 {
  margin-right: 3.25rem;
  : 3.25rem; }

.mr-14 {
  margin-right: 3.5rem;
  : 3.5rem; }

.mr-15 {
  margin-right: 3.75rem;
  : 3.75rem; }

.mr-16 {
  margin-right: 4rem;
  : 4rem; }

.mr-17 {
  margin-right: 4.25rem;
  : 4.25rem; }

.mr-18 {
  margin-right: 4.5rem;
  : 4.5rem; }

.mr-19 {
  margin-right: 4.75rem;
  : 4.75rem; }

.mr-20 {
  margin-right: 5rem;
  : 5rem; }

.mb-0 {
  margin-bottom: 0rem;
  : 0rem; }

.mb-1 {
  margin-bottom: 0.25rem;
  : 0.25rem; }

.mb-2 {
  margin-bottom: 0.5rem;
  : 0.5rem; }

.mb-3 {
  margin-bottom: 0.75rem;
  : 0.75rem; }

.mb-4 {
  margin-bottom: 1rem;
  : 1rem; }

.mb-5 {
  margin-bottom: 1.25rem;
  : 1.25rem; }

.mb-6 {
  margin-bottom: 1.5rem;
  : 1.5rem; }

.mb-7 {
  margin-bottom: 1.75rem;
  : 1.75rem; }

.mb-8 {
  margin-bottom: 2rem;
  : 2rem; }

.mb-9 {
  margin-bottom: 2.25rem;
  : 2.25rem; }

.mb-10 {
  margin-bottom: 2.5rem;
  : 2.5rem; }

.mb-11 {
  margin-bottom: 2.75rem;
  : 2.75rem; }

.mb-12 {
  margin-bottom: 3rem;
  : 3rem; }

.mb-13 {
  margin-bottom: 3.25rem;
  : 3.25rem; }

.mb-14 {
  margin-bottom: 3.5rem;
  : 3.5rem; }

.mb-15 {
  margin-bottom: 3.75rem;
  : 3.75rem; }

.mb-16 {
  margin-bottom: 4rem;
  : 4rem; }

.mb-17 {
  margin-bottom: 4.25rem;
  : 4.25rem; }

.mb-18 {
  margin-bottom: 4.5rem;
  : 4.5rem; }

.mb-19 {
  margin-bottom: 4.75rem;
  : 4.75rem; }

.mb-20 {
  margin-bottom: 5rem;
  : 5rem; }

.ml-0 {
  margin-left: 0rem;
  : 0rem; }

.ml-1 {
  margin-left: 0.25rem;
  : 0.25rem; }

.ml-2 {
  margin-left: 0.5rem;
  : 0.5rem; }

.ml-3 {
  margin-left: 0.75rem;
  : 0.75rem; }

.ml-4 {
  margin-left: 1rem;
  : 1rem; }

.ml-5 {
  margin-left: 1.25rem;
  : 1.25rem; }

.ml-6 {
  margin-left: 1.5rem;
  : 1.5rem; }

.ml-7 {
  margin-left: 1.75rem;
  : 1.75rem; }

.ml-8 {
  margin-left: 2rem;
  : 2rem; }

.ml-9 {
  margin-left: 2.25rem;
  : 2.25rem; }

.ml-10 {
  margin-left: 2.5rem;
  : 2.5rem; }

.ml-11 {
  margin-left: 2.75rem;
  : 2.75rem; }

.ml-12 {
  margin-left: 3rem;
  : 3rem; }

.ml-13 {
  margin-left: 3.25rem;
  : 3.25rem; }

.ml-14 {
  margin-left: 3.5rem;
  : 3.5rem; }

.ml-15 {
  margin-left: 3.75rem;
  : 3.75rem; }

.ml-16 {
  margin-left: 4rem;
  : 4rem; }

.ml-17 {
  margin-left: 4.25rem;
  : 4.25rem; }

.ml-18 {
  margin-left: 4.5rem;
  : 4.5rem; }

.ml-19 {
  margin-left: 4.75rem;
  : 4.75rem; }

.ml-20 {
  margin-left: 5rem;
  : 5rem; }

.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem; }

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem; }

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem; }

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem; }

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem; }

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem; }

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem; }

.mx-13 {
  margin-left: 3.25rem;
  margin-right: 3.25rem; }

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem; }

.mx-15 {
  margin-left: 3.75rem;
  margin-right: 3.75rem; }

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem; }

.mx-17 {
  margin-left: 4.25rem;
  margin-right: 4.25rem; }

.mx-18 {
  margin-left: 4.5rem;
  margin-right: 4.5rem; }

.mx-19 {
  margin-left: 4.75rem;
  margin-right: 4.75rem; }

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem; }

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem; }

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem; }

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.my-13 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem; }

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem; }

.my-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.my-17 {
  margin-top: 4.25rem;
  margin-bottom: 4.25rem; }

.my-18 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem; }

.my-19 {
  margin-top: 4.75rem;
  margin-bottom: 4.75rem; }

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.m-0 {
  margin: 0rem;
  : 0rem; }

.m-1 {
  margin: 0.25rem;
  : 0.25rem; }

.m-2 {
  margin: 0.5rem;
  : 0.5rem; }

.m-3 {
  margin: 0.75rem;
  : 0.75rem; }

.m-4 {
  margin: 1rem;
  : 1rem; }

.m-5 {
  margin: 1.25rem;
  : 1.25rem; }

.m-6 {
  margin: 1.5rem;
  : 1.5rem; }

.m-7 {
  margin: 1.75rem;
  : 1.75rem; }

.m-8 {
  margin: 2rem;
  : 2rem; }

.m-9 {
  margin: 2.25rem;
  : 2.25rem; }

.m-10 {
  margin: 2.5rem;
  : 2.5rem; }

.m-11 {
  margin: 2.75rem;
  : 2.75rem; }

.m-12 {
  margin: 3rem;
  : 3rem; }

.m-13 {
  margin: 3.25rem;
  : 3.25rem; }

.m-14 {
  margin: 3.5rem;
  : 3.5rem; }

.m-15 {
  margin: 3.75rem;
  : 3.75rem; }

.m-16 {
  margin: 4rem;
  : 4rem; }

.m-17 {
  margin: 4.25rem;
  : 4.25rem; }

.m-18 {
  margin: 4.5rem;
  : 4.5rem; }

.m-19 {
  margin: 4.75rem;
  : 4.75rem; }

.m-20 {
  margin: 5rem;
  : 5rem; }

.pt-0 {
  padding-top: 0rem;
  : 0rem; }

.pt-1 {
  padding-top: 0.25rem;
  : 0.25rem; }

.pt-2 {
  padding-top: 0.5rem;
  : 0.5rem; }

.pt-3 {
  padding-top: 0.75rem;
  : 0.75rem; }

.pt-4 {
  padding-top: 1rem;
  : 1rem; }

.pt-5 {
  padding-top: 1.25rem;
  : 1.25rem; }

.pt-6 {
  padding-top: 1.5rem;
  : 1.5rem; }

.pt-7 {
  padding-top: 1.75rem;
  : 1.75rem; }

.pt-8 {
  padding-top: 2rem;
  : 2rem; }

.pt-9 {
  padding-top: 2.25rem;
  : 2.25rem; }

.pt-10 {
  padding-top: 2.5rem;
  : 2.5rem; }

.pt-11 {
  padding-top: 2.75rem;
  : 2.75rem; }

.pt-12 {
  padding-top: 3rem;
  : 3rem; }

.pt-13 {
  padding-top: 3.25rem;
  : 3.25rem; }

.pt-14 {
  padding-top: 3.5rem;
  : 3.5rem; }

.pt-15 {
  padding-top: 3.75rem;
  : 3.75rem; }

.pt-16 {
  padding-top: 4rem;
  : 4rem; }

.pt-17 {
  padding-top: 4.25rem;
  : 4.25rem; }

.pt-18 {
  padding-top: 4.5rem;
  : 4.5rem; }

.pt-19 {
  padding-top: 4.75rem;
  : 4.75rem; }

.pt-20 {
  padding-top: 5rem;
  : 5rem; }

.pr-0 {
  padding-right: 0rem;
  : 0rem; }

.pr-1 {
  padding-right: 0.25rem;
  : 0.25rem; }

.pr-2 {
  padding-right: 0.5rem;
  : 0.5rem; }

.pr-3 {
  padding-right: 0.75rem;
  : 0.75rem; }

.pr-4 {
  padding-right: 1rem;
  : 1rem; }

.pr-5 {
  padding-right: 1.25rem;
  : 1.25rem; }

.pr-6 {
  padding-right: 1.5rem;
  : 1.5rem; }

.pr-7 {
  padding-right: 1.75rem;
  : 1.75rem; }

.pr-8 {
  padding-right: 2rem;
  : 2rem; }

.pr-9 {
  padding-right: 2.25rem;
  : 2.25rem; }

.pr-10 {
  padding-right: 2.5rem;
  : 2.5rem; }

.pr-11 {
  padding-right: 2.75rem;
  : 2.75rem; }

.pr-12 {
  padding-right: 3rem;
  : 3rem; }

.pr-13 {
  padding-right: 3.25rem;
  : 3.25rem; }

.pr-14 {
  padding-right: 3.5rem;
  : 3.5rem; }

.pr-15 {
  padding-right: 3.75rem;
  : 3.75rem; }

.pr-16 {
  padding-right: 4rem;
  : 4rem; }

.pr-17 {
  padding-right: 4.25rem;
  : 4.25rem; }

.pr-18 {
  padding-right: 4.5rem;
  : 4.5rem; }

.pr-19 {
  padding-right: 4.75rem;
  : 4.75rem; }

.pr-20 {
  padding-right: 5rem;
  : 5rem; }

.pb-0 {
  padding-bottom: 0rem;
  : 0rem; }

.pb-1 {
  padding-bottom: 0.25rem;
  : 0.25rem; }

.pb-2 {
  padding-bottom: 0.5rem;
  : 0.5rem; }

.pb-3 {
  padding-bottom: 0.75rem;
  : 0.75rem; }

.pb-4 {
  padding-bottom: 1rem;
  : 1rem; }

.pb-5 {
  padding-bottom: 1.25rem;
  : 1.25rem; }

.pb-6 {
  padding-bottom: 1.5rem;
  : 1.5rem; }

.pb-7 {
  padding-bottom: 1.75rem;
  : 1.75rem; }

.pb-8 {
  padding-bottom: 2rem;
  : 2rem; }

.pb-9 {
  padding-bottom: 2.25rem;
  : 2.25rem; }

.pb-10 {
  padding-bottom: 2.5rem;
  : 2.5rem; }

.pb-11 {
  padding-bottom: 2.75rem;
  : 2.75rem; }

.pb-12 {
  padding-bottom: 3rem;
  : 3rem; }

.pb-13 {
  padding-bottom: 3.25rem;
  : 3.25rem; }

.pb-14 {
  padding-bottom: 3.5rem;
  : 3.5rem; }

.pb-15 {
  padding-bottom: 3.75rem;
  : 3.75rem; }

.pb-16 {
  padding-bottom: 4rem;
  : 4rem; }

.pb-17 {
  padding-bottom: 4.25rem;
  : 4.25rem; }

.pb-18 {
  padding-bottom: 4.5rem;
  : 4.5rem; }

.pb-19 {
  padding-bottom: 4.75rem;
  : 4.75rem; }

.pb-20 {
  padding-bottom: 5rem;
  : 5rem; }

.pl-0 {
  padding-left: 0rem;
  : 0rem; }

.pl-1 {
  padding-left: 0.25rem;
  : 0.25rem; }

.pl-2 {
  padding-left: 0.5rem;
  : 0.5rem; }

.pl-3 {
  padding-left: 0.75rem;
  : 0.75rem; }

.pl-4 {
  padding-left: 1rem;
  : 1rem; }

.pl-5 {
  padding-left: 1.25rem;
  : 1.25rem; }

.pl-6 {
  padding-left: 1.5rem;
  : 1.5rem; }

.pl-7 {
  padding-left: 1.75rem;
  : 1.75rem; }

.pl-8 {
  padding-left: 2rem;
  : 2rem; }

.pl-9 {
  padding-left: 2.25rem;
  : 2.25rem; }

.pl-10 {
  padding-left: 2.5rem;
  : 2.5rem; }

.pl-11 {
  padding-left: 2.75rem;
  : 2.75rem; }

.pl-12 {
  padding-left: 3rem;
  : 3rem; }

.pl-13 {
  padding-left: 3.25rem;
  : 3.25rem; }

.pl-14 {
  padding-left: 3.5rem;
  : 3.5rem; }

.pl-15 {
  padding-left: 3.75rem;
  : 3.75rem; }

.pl-16 {
  padding-left: 4rem;
  : 4rem; }

.pl-17 {
  padding-left: 4.25rem;
  : 4.25rem; }

.pl-18 {
  padding-left: 4.5rem;
  : 4.5rem; }

.pl-19 {
  padding-left: 4.75rem;
  : 4.75rem; }

.pl-20 {
  padding-left: 5rem;
  : 5rem; }

.px-0 {
  padding-left: 0rem;
  padding-right: 0rem; }

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem; }

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem; }

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem; }

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem; }

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem; }

.px-13 {
  padding-left: 3.25rem;
  padding-right: 3.25rem; }

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem; }

.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem; }

.px-17 {
  padding-left: 4.25rem;
  padding-right: 4.25rem; }

.px-18 {
  padding-left: 4.5rem;
  padding-right: 4.5rem; }

.px-19 {
  padding-left: 4.75rem;
  padding-right: 4.75rem; }

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem; }

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; }

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem; }

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem; }

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.py-13 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem; }

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem; }

.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.py-17 {
  padding-top: 4.25rem;
  padding-bottom: 4.25rem; }

.py-18 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem; }

.py-19 {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem; }

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.p-0 {
  padding: 0rem;
  : 0rem; }

.p-1 {
  padding: 0.25rem;
  : 0.25rem; }

.p-2 {
  padding: 0.5rem;
  : 0.5rem; }

.p-3 {
  padding: 0.75rem;
  : 0.75rem; }

.p-4 {
  padding: 1rem;
  : 1rem; }

.p-5 {
  padding: 1.25rem;
  : 1.25rem; }

.p-6 {
  padding: 1.5rem;
  : 1.5rem; }

.p-7 {
  padding: 1.75rem;
  : 1.75rem; }

.p-8 {
  padding: 2rem;
  : 2rem; }

.p-9 {
  padding: 2.25rem;
  : 2.25rem; }

.p-10 {
  padding: 2.5rem;
  : 2.5rem; }

.p-11 {
  padding: 2.75rem;
  : 2.75rem; }

.p-12 {
  padding: 3rem;
  : 3rem; }

.p-13 {
  padding: 3.25rem;
  : 3.25rem; }

.p-14 {
  padding: 3.5rem;
  : 3.5rem; }

.p-15 {
  padding: 3.75rem;
  : 3.75rem; }

.p-16 {
  padding: 4rem;
  : 4rem; }

.p-17 {
  padding: 4.25rem;
  : 4.25rem; }

.p-18 {
  padding: 4.5rem;
  : 4.5rem; }

.p-19 {
  padding: 4.75rem;
  : 4.75rem; }

.p-20 {
  padding: 5rem;
  : 5rem; }

@media (min-width: 577px) {
  .pt-sm-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-sm-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-sm-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-sm-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-sm-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-sm-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-sm-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-sm-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-sm-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-sm-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-sm-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-sm-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-sm-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-sm-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-sm-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-sm-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-sm-16 {
    padding-top: 4rem;
    : 4rem; }
  .pt-sm-17 {
    padding-top: 4.25rem;
    : 4.25rem; }
  .pt-sm-18 {
    padding-top: 4.5rem;
    : 4.5rem; }
  .pt-sm-19 {
    padding-top: 4.75rem;
    : 4.75rem; }
  .pt-sm-20 {
    padding-top: 5rem;
    : 5rem; }
  .pr-sm-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-sm-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-sm-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-sm-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-sm-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-sm-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-sm-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-sm-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-sm-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-sm-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-sm-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-sm-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-sm-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-sm-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-sm-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-sm-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-sm-16 {
    padding-right: 4rem;
    : 4rem; }
  .pr-sm-17 {
    padding-right: 4.25rem;
    : 4.25rem; }
  .pr-sm-18 {
    padding-right: 4.5rem;
    : 4.5rem; }
  .pr-sm-19 {
    padding-right: 4.75rem;
    : 4.75rem; }
  .pr-sm-20 {
    padding-right: 5rem;
    : 5rem; }
  .pb-sm-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-sm-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-sm-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-sm-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-sm-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-sm-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-sm-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-sm-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-sm-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-sm-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-sm-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-sm-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-sm-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-sm-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-sm-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-sm-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-sm-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pb-sm-17 {
    padding-bottom: 4.25rem;
    : 4.25rem; }
  .pb-sm-18 {
    padding-bottom: 4.5rem;
    : 4.5rem; }
  .pb-sm-19 {
    padding-bottom: 4.75rem;
    : 4.75rem; }
  .pb-sm-20 {
    padding-bottom: 5rem;
    : 5rem; }
  .pl-sm-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-sm-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-sm-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-sm-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-sm-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-sm-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-sm-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-sm-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-sm-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-sm-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-sm-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-sm-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-sm-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-sm-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-sm-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-sm-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-sm-16 {
    padding-left: 4rem;
    : 4rem; }
  .pl-sm-17 {
    padding-left: 4.25rem;
    : 4.25rem; }
  .pl-sm-18 {
    padding-left: 4.5rem;
    : 4.5rem; }
  .pl-sm-19 {
    padding-left: 4.75rem;
    : 4.75rem; }
  .pl-sm-20 {
    padding-left: 5rem;
    : 5rem; }
  .px-sm-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-sm-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-sm-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-sm-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-sm-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-sm-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-sm-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-sm-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-sm-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-sm-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-sm-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-sm-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-sm-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-sm-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-sm-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-sm-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-sm-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .px-sm-17 {
    padding-left: 4.25rem;
    padding-right: 4.25rem; }
  .px-sm-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem; }
  .px-sm-19 {
    padding-left: 4.75rem;
    padding-right: 4.75rem; }
  .px-sm-20 {
    padding-left: 5rem;
    padding-right: 5rem; }
  .py-sm-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-sm-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-sm-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-sm-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-sm-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-sm-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-sm-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-sm-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-sm-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-sm-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-sm-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-sm-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-sm-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-sm-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-sm-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .py-sm-17 {
    padding-top: 4.25rem;
    padding-bottom: 4.25rem; }
  .py-sm-18 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem; }
  .py-sm-19 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem; }
  .py-sm-20 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .p-sm-0 {
    padding: 0rem;
    : 0rem; }
  .p-sm-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-sm-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-sm-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-sm-4 {
    padding: 1rem;
    : 1rem; }
  .p-sm-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-sm-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-sm-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-sm-8 {
    padding: 2rem;
    : 2rem; }
  .p-sm-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-sm-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-sm-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-sm-12 {
    padding: 3rem;
    : 3rem; }
  .p-sm-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-sm-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-sm-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-sm-16 {
    padding: 4rem;
    : 4rem; }
  .p-sm-17 {
    padding: 4.25rem;
    : 4.25rem; }
  .p-sm-18 {
    padding: 4.5rem;
    : 4.5rem; }
  .p-sm-19 {
    padding: 4.75rem;
    : 4.75rem; }
  .p-sm-20 {
    padding: 5rem;
    : 5rem; } }

@media (min-width: 577px) {
  .mt-sm-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-sm-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-sm-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-sm-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-sm-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-sm-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-sm-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-sm-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-sm-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-sm-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-sm-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-sm-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-sm-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-sm-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-sm-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-sm-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-sm-16 {
    margin-top: 4rem;
    : 4rem; }
  .mt-sm-17 {
    margin-top: 4.25rem;
    : 4.25rem; }
  .mt-sm-18 {
    margin-top: 4.5rem;
    : 4.5rem; }
  .mt-sm-19 {
    margin-top: 4.75rem;
    : 4.75rem; }
  .mt-sm-20 {
    margin-top: 5rem;
    : 5rem; }
  .mr-sm-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-sm-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-sm-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-sm-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-sm-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-sm-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-sm-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-sm-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-sm-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-sm-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-sm-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-sm-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-sm-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-sm-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-sm-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-sm-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-sm-16 {
    margin-right: 4rem;
    : 4rem; }
  .mr-sm-17 {
    margin-right: 4.25rem;
    : 4.25rem; }
  .mr-sm-18 {
    margin-right: 4.5rem;
    : 4.5rem; }
  .mr-sm-19 {
    margin-right: 4.75rem;
    : 4.75rem; }
  .mr-sm-20 {
    margin-right: 5rem;
    : 5rem; }
  .mb-sm-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-sm-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-sm-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-sm-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-sm-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-sm-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-sm-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-sm-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-sm-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-sm-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-sm-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-sm-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-sm-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-sm-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-sm-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-sm-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-sm-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .mb-sm-17 {
    margin-bottom: 4.25rem;
    : 4.25rem; }
  .mb-sm-18 {
    margin-bottom: 4.5rem;
    : 4.5rem; }
  .mb-sm-19 {
    margin-bottom: 4.75rem;
    : 4.75rem; }
  .mb-sm-20 {
    margin-bottom: 5rem;
    : 5rem; }
  .ml-sm-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-sm-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-sm-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-sm-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-sm-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-sm-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-sm-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-sm-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-sm-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-sm-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-sm-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-sm-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-sm-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-sm-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-sm-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-sm-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-sm-16 {
    margin-left: 4rem;
    : 4rem; }
  .ml-sm-17 {
    margin-left: 4.25rem;
    : 4.25rem; }
  .ml-sm-18 {
    margin-left: 4.5rem;
    : 4.5rem; }
  .ml-sm-19 {
    margin-left: 4.75rem;
    : 4.75rem; }
  .ml-sm-20 {
    margin-left: 5rem;
    : 5rem; }
  .mx-sm-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-sm-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-sm-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-sm-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-sm-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-sm-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-sm-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-sm-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-sm-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-sm-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-sm-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-sm-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-sm-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-sm-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-sm-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-sm-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-sm-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .mx-sm-17 {
    margin-left: 4.25rem;
    margin-right: 4.25rem; }
  .mx-sm-18 {
    margin-left: 4.5rem;
    margin-right: 4.5rem; }
  .mx-sm-19 {
    margin-left: 4.75rem;
    margin-right: 4.75rem; }
  .mx-sm-20 {
    margin-left: 5rem;
    margin-right: 5rem; }
  .my-sm-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-sm-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-sm-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-sm-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-sm-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-sm-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-sm-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-sm-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-sm-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-sm-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-sm-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-sm-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-sm-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-sm-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-sm-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-sm-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-sm-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .my-sm-17 {
    margin-top: 4.25rem;
    margin-bottom: 4.25rem; }
  .my-sm-18 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem; }
  .my-sm-19 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem; }
  .my-sm-20 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .m-sm-0 {
    margin: 0rem;
    : 0rem; }
  .m-sm-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-sm-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-sm-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-sm-4 {
    margin: 1rem;
    : 1rem; }
  .m-sm-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-sm-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-sm-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-sm-8 {
    margin: 2rem;
    : 2rem; }
  .m-sm-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-sm-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-sm-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-sm-12 {
    margin: 3rem;
    : 3rem; }
  .m-sm-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-sm-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-sm-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-sm-16 {
    margin: 4rem;
    : 4rem; }
  .m-sm-17 {
    margin: 4.25rem;
    : 4.25rem; }
  .m-sm-18 {
    margin: 4.5rem;
    : 4.5rem; }
  .m-sm-19 {
    margin: 4.75rem;
    : 4.75rem; }
  .m-sm-20 {
    margin: 5rem;
    : 5rem; } }

@media (min-width: 769px) {
  .pt-md-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-md-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-md-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-md-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-md-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-md-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-md-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-md-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-md-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-md-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-md-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-md-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-md-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-md-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-md-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-md-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-md-16 {
    padding-top: 4rem;
    : 4rem; }
  .pt-md-17 {
    padding-top: 4.25rem;
    : 4.25rem; }
  .pt-md-18 {
    padding-top: 4.5rem;
    : 4.5rem; }
  .pt-md-19 {
    padding-top: 4.75rem;
    : 4.75rem; }
  .pt-md-20 {
    padding-top: 5rem;
    : 5rem; }
  .pr-md-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-md-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-md-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-md-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-md-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-md-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-md-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-md-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-md-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-md-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-md-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-md-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-md-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-md-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-md-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-md-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-md-16 {
    padding-right: 4rem;
    : 4rem; }
  .pr-md-17 {
    padding-right: 4.25rem;
    : 4.25rem; }
  .pr-md-18 {
    padding-right: 4.5rem;
    : 4.5rem; }
  .pr-md-19 {
    padding-right: 4.75rem;
    : 4.75rem; }
  .pr-md-20 {
    padding-right: 5rem;
    : 5rem; }
  .pb-md-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-md-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-md-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-md-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-md-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-md-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-md-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-md-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-md-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-md-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-md-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-md-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-md-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-md-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-md-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-md-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-md-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pb-md-17 {
    padding-bottom: 4.25rem;
    : 4.25rem; }
  .pb-md-18 {
    padding-bottom: 4.5rem;
    : 4.5rem; }
  .pb-md-19 {
    padding-bottom: 4.75rem;
    : 4.75rem; }
  .pb-md-20 {
    padding-bottom: 5rem;
    : 5rem; }
  .pl-md-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-md-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-md-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-md-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-md-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-md-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-md-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-md-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-md-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-md-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-md-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-md-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-md-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-md-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-md-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-md-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-md-16 {
    padding-left: 4rem;
    : 4rem; }
  .pl-md-17 {
    padding-left: 4.25rem;
    : 4.25rem; }
  .pl-md-18 {
    padding-left: 4.5rem;
    : 4.5rem; }
  .pl-md-19 {
    padding-left: 4.75rem;
    : 4.75rem; }
  .pl-md-20 {
    padding-left: 5rem;
    : 5rem; }
  .px-md-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-md-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-md-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-md-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-md-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-md-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-md-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-md-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-md-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-md-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-md-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-md-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-md-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-md-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-md-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-md-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-md-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .px-md-17 {
    padding-left: 4.25rem;
    padding-right: 4.25rem; }
  .px-md-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem; }
  .px-md-19 {
    padding-left: 4.75rem;
    padding-right: 4.75rem; }
  .px-md-20 {
    padding-left: 5rem;
    padding-right: 5rem; }
  .py-md-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-md-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-md-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-md-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-md-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-md-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-md-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-md-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-md-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-md-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-md-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-md-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-md-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-md-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-md-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-md-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-md-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .py-md-17 {
    padding-top: 4.25rem;
    padding-bottom: 4.25rem; }
  .py-md-18 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem; }
  .py-md-19 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem; }
  .py-md-20 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .p-md-0 {
    padding: 0rem;
    : 0rem; }
  .p-md-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-md-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-md-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-md-4 {
    padding: 1rem;
    : 1rem; }
  .p-md-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-md-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-md-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-md-8 {
    padding: 2rem;
    : 2rem; }
  .p-md-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-md-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-md-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-md-12 {
    padding: 3rem;
    : 3rem; }
  .p-md-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-md-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-md-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-md-16 {
    padding: 4rem;
    : 4rem; }
  .p-md-17 {
    padding: 4.25rem;
    : 4.25rem; }
  .p-md-18 {
    padding: 4.5rem;
    : 4.5rem; }
  .p-md-19 {
    padding: 4.75rem;
    : 4.75rem; }
  .p-md-20 {
    padding: 5rem;
    : 5rem; } }

@media (min-width: 769px) {
  .mt-md-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-md-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-md-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-md-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-md-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-md-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-md-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-md-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-md-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-md-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-md-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-md-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-md-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-md-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-md-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-md-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-md-16 {
    margin-top: 4rem;
    : 4rem; }
  .mt-md-17 {
    margin-top: 4.25rem;
    : 4.25rem; }
  .mt-md-18 {
    margin-top: 4.5rem;
    : 4.5rem; }
  .mt-md-19 {
    margin-top: 4.75rem;
    : 4.75rem; }
  .mt-md-20 {
    margin-top: 5rem;
    : 5rem; }
  .mr-md-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-md-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-md-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-md-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-md-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-md-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-md-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-md-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-md-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-md-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-md-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-md-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-md-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-md-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-md-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-md-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-md-16 {
    margin-right: 4rem;
    : 4rem; }
  .mr-md-17 {
    margin-right: 4.25rem;
    : 4.25rem; }
  .mr-md-18 {
    margin-right: 4.5rem;
    : 4.5rem; }
  .mr-md-19 {
    margin-right: 4.75rem;
    : 4.75rem; }
  .mr-md-20 {
    margin-right: 5rem;
    : 5rem; }
  .mb-md-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-md-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-md-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-md-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-md-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-md-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-md-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-md-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-md-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-md-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-md-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-md-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-md-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-md-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-md-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-md-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-md-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .mb-md-17 {
    margin-bottom: 4.25rem;
    : 4.25rem; }
  .mb-md-18 {
    margin-bottom: 4.5rem;
    : 4.5rem; }
  .mb-md-19 {
    margin-bottom: 4.75rem;
    : 4.75rem; }
  .mb-md-20 {
    margin-bottom: 5rem;
    : 5rem; }
  .ml-md-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-md-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-md-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-md-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-md-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-md-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-md-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-md-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-md-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-md-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-md-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-md-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-md-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-md-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-md-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-md-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-md-16 {
    margin-left: 4rem;
    : 4rem; }
  .ml-md-17 {
    margin-left: 4.25rem;
    : 4.25rem; }
  .ml-md-18 {
    margin-left: 4.5rem;
    : 4.5rem; }
  .ml-md-19 {
    margin-left: 4.75rem;
    : 4.75rem; }
  .ml-md-20 {
    margin-left: 5rem;
    : 5rem; }
  .mx-md-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-md-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-md-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-md-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-md-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-md-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-md-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-md-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-md-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-md-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-md-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-md-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-md-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-md-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-md-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-md-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-md-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .mx-md-17 {
    margin-left: 4.25rem;
    margin-right: 4.25rem; }
  .mx-md-18 {
    margin-left: 4.5rem;
    margin-right: 4.5rem; }
  .mx-md-19 {
    margin-left: 4.75rem;
    margin-right: 4.75rem; }
  .mx-md-20 {
    margin-left: 5rem;
    margin-right: 5rem; }
  .my-md-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-md-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-md-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-md-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-md-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-md-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-md-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-md-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-md-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-md-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-md-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-md-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-md-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-md-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-md-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-md-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-md-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .my-md-17 {
    margin-top: 4.25rem;
    margin-bottom: 4.25rem; }
  .my-md-18 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem; }
  .my-md-19 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem; }
  .my-md-20 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .m-md-0 {
    margin: 0rem;
    : 0rem; }
  .m-md-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-md-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-md-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-md-4 {
    margin: 1rem;
    : 1rem; }
  .m-md-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-md-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-md-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-md-8 {
    margin: 2rem;
    : 2rem; }
  .m-md-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-md-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-md-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-md-12 {
    margin: 3rem;
    : 3rem; }
  .m-md-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-md-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-md-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-md-16 {
    margin: 4rem;
    : 4rem; }
  .m-md-17 {
    margin: 4.25rem;
    : 4.25rem; }
  .m-md-18 {
    margin: 4.5rem;
    : 4.5rem; }
  .m-md-19 {
    margin: 4.75rem;
    : 4.75rem; }
  .m-md-20 {
    margin: 5rem;
    : 5rem; } }

@media (min-width: 1025px) {
  .pt-lg-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-lg-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-lg-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-lg-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-lg-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-lg-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-lg-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-lg-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-lg-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-lg-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-lg-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-lg-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-lg-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-lg-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-lg-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-lg-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-lg-16 {
    padding-top: 4rem;
    : 4rem; }
  .pt-lg-17 {
    padding-top: 4.25rem;
    : 4.25rem; }
  .pt-lg-18 {
    padding-top: 4.5rem;
    : 4.5rem; }
  .pt-lg-19 {
    padding-top: 4.75rem;
    : 4.75rem; }
  .pt-lg-20 {
    padding-top: 5rem;
    : 5rem; }
  .pr-lg-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-lg-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-lg-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-lg-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-lg-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-lg-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-lg-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-lg-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-lg-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-lg-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-lg-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-lg-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-lg-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-lg-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-lg-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-lg-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-lg-16 {
    padding-right: 4rem;
    : 4rem; }
  .pr-lg-17 {
    padding-right: 4.25rem;
    : 4.25rem; }
  .pr-lg-18 {
    padding-right: 4.5rem;
    : 4.5rem; }
  .pr-lg-19 {
    padding-right: 4.75rem;
    : 4.75rem; }
  .pr-lg-20 {
    padding-right: 5rem;
    : 5rem; }
  .pb-lg-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-lg-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-lg-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-lg-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-lg-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-lg-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-lg-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-lg-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-lg-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-lg-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-lg-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-lg-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-lg-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-lg-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-lg-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-lg-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-lg-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pb-lg-17 {
    padding-bottom: 4.25rem;
    : 4.25rem; }
  .pb-lg-18 {
    padding-bottom: 4.5rem;
    : 4.5rem; }
  .pb-lg-19 {
    padding-bottom: 4.75rem;
    : 4.75rem; }
  .pb-lg-20 {
    padding-bottom: 5rem;
    : 5rem; }
  .pl-lg-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-lg-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-lg-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-lg-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-lg-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-lg-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-lg-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-lg-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-lg-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-lg-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-lg-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-lg-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-lg-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-lg-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-lg-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-lg-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-lg-16 {
    padding-left: 4rem;
    : 4rem; }
  .pl-lg-17 {
    padding-left: 4.25rem;
    : 4.25rem; }
  .pl-lg-18 {
    padding-left: 4.5rem;
    : 4.5rem; }
  .pl-lg-19 {
    padding-left: 4.75rem;
    : 4.75rem; }
  .pl-lg-20 {
    padding-left: 5rem;
    : 5rem; }
  .px-lg-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-lg-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-lg-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-lg-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-lg-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-lg-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-lg-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-lg-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-lg-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-lg-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-lg-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-lg-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-lg-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-lg-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-lg-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-lg-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-lg-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .px-lg-17 {
    padding-left: 4.25rem;
    padding-right: 4.25rem; }
  .px-lg-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem; }
  .px-lg-19 {
    padding-left: 4.75rem;
    padding-right: 4.75rem; }
  .px-lg-20 {
    padding-left: 5rem;
    padding-right: 5rem; }
  .py-lg-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-lg-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-lg-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-lg-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-lg-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-lg-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-lg-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-lg-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-lg-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-lg-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-lg-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-lg-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-lg-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-lg-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-lg-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-lg-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-lg-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .py-lg-17 {
    padding-top: 4.25rem;
    padding-bottom: 4.25rem; }
  .py-lg-18 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem; }
  .py-lg-19 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem; }
  .py-lg-20 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .p-lg-0 {
    padding: 0rem;
    : 0rem; }
  .p-lg-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-lg-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-lg-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-lg-4 {
    padding: 1rem;
    : 1rem; }
  .p-lg-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-lg-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-lg-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-lg-8 {
    padding: 2rem;
    : 2rem; }
  .p-lg-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-lg-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-lg-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-lg-12 {
    padding: 3rem;
    : 3rem; }
  .p-lg-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-lg-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-lg-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-lg-16 {
    padding: 4rem;
    : 4rem; }
  .p-lg-17 {
    padding: 4.25rem;
    : 4.25rem; }
  .p-lg-18 {
    padding: 4.5rem;
    : 4.5rem; }
  .p-lg-19 {
    padding: 4.75rem;
    : 4.75rem; }
  .p-lg-20 {
    padding: 5rem;
    : 5rem; } }

@media (min-width: 1025px) {
  .mt-lg-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-lg-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-lg-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-lg-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-lg-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-lg-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-lg-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-lg-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-lg-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-lg-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-lg-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-lg-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-lg-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-lg-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-lg-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-lg-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-lg-16 {
    margin-top: 4rem;
    : 4rem; }
  .mt-lg-17 {
    margin-top: 4.25rem;
    : 4.25rem; }
  .mt-lg-18 {
    margin-top: 4.5rem;
    : 4.5rem; }
  .mt-lg-19 {
    margin-top: 4.75rem;
    : 4.75rem; }
  .mt-lg-20 {
    margin-top: 5rem;
    : 5rem; }
  .mr-lg-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-lg-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-lg-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-lg-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-lg-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-lg-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-lg-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-lg-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-lg-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-lg-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-lg-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-lg-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-lg-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-lg-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-lg-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-lg-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-lg-16 {
    margin-right: 4rem;
    : 4rem; }
  .mr-lg-17 {
    margin-right: 4.25rem;
    : 4.25rem; }
  .mr-lg-18 {
    margin-right: 4.5rem;
    : 4.5rem; }
  .mr-lg-19 {
    margin-right: 4.75rem;
    : 4.75rem; }
  .mr-lg-20 {
    margin-right: 5rem;
    : 5rem; }
  .mb-lg-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-lg-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-lg-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-lg-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-lg-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-lg-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-lg-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-lg-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-lg-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-lg-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-lg-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-lg-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-lg-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-lg-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-lg-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-lg-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-lg-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .mb-lg-17 {
    margin-bottom: 4.25rem;
    : 4.25rem; }
  .mb-lg-18 {
    margin-bottom: 4.5rem;
    : 4.5rem; }
  .mb-lg-19 {
    margin-bottom: 4.75rem;
    : 4.75rem; }
  .mb-lg-20 {
    margin-bottom: 5rem;
    : 5rem; }
  .ml-lg-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-lg-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-lg-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-lg-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-lg-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-lg-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-lg-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-lg-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-lg-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-lg-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-lg-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-lg-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-lg-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-lg-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-lg-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-lg-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-lg-16 {
    margin-left: 4rem;
    : 4rem; }
  .ml-lg-17 {
    margin-left: 4.25rem;
    : 4.25rem; }
  .ml-lg-18 {
    margin-left: 4.5rem;
    : 4.5rem; }
  .ml-lg-19 {
    margin-left: 4.75rem;
    : 4.75rem; }
  .ml-lg-20 {
    margin-left: 5rem;
    : 5rem; }
  .mx-lg-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-lg-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-lg-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-lg-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-lg-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-lg-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-lg-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-lg-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-lg-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-lg-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-lg-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-lg-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-lg-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-lg-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-lg-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-lg-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-lg-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .mx-lg-17 {
    margin-left: 4.25rem;
    margin-right: 4.25rem; }
  .mx-lg-18 {
    margin-left: 4.5rem;
    margin-right: 4.5rem; }
  .mx-lg-19 {
    margin-left: 4.75rem;
    margin-right: 4.75rem; }
  .mx-lg-20 {
    margin-left: 5rem;
    margin-right: 5rem; }
  .my-lg-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-lg-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-lg-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-lg-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-lg-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-lg-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-lg-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-lg-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-lg-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-lg-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-lg-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-lg-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-lg-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-lg-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-lg-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-lg-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-lg-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .my-lg-17 {
    margin-top: 4.25rem;
    margin-bottom: 4.25rem; }
  .my-lg-18 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem; }
  .my-lg-19 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem; }
  .my-lg-20 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .m-lg-0 {
    margin: 0rem;
    : 0rem; }
  .m-lg-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-lg-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-lg-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-lg-4 {
    margin: 1rem;
    : 1rem; }
  .m-lg-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-lg-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-lg-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-lg-8 {
    margin: 2rem;
    : 2rem; }
  .m-lg-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-lg-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-lg-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-lg-12 {
    margin: 3rem;
    : 3rem; }
  .m-lg-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-lg-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-lg-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-lg-16 {
    margin: 4rem;
    : 4rem; }
  .m-lg-17 {
    margin: 4.25rem;
    : 4.25rem; }
  .m-lg-18 {
    margin: 4.5rem;
    : 4.5rem; }
  .m-lg-19 {
    margin: 4.75rem;
    : 4.75rem; }
  .m-lg-20 {
    margin: 5rem;
    : 5rem; } }

@media (min-width: 1281px) {
  .pt-xl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-xl-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-xl-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-xl-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-xl-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-xl-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-xl-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-xl-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-xl-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-xl-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-xl-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-xl-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-xl-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-xl-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-xl-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-xl-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-xl-16 {
    padding-top: 4rem;
    : 4rem; }
  .pt-xl-17 {
    padding-top: 4.25rem;
    : 4.25rem; }
  .pt-xl-18 {
    padding-top: 4.5rem;
    : 4.5rem; }
  .pt-xl-19 {
    padding-top: 4.75rem;
    : 4.75rem; }
  .pt-xl-20 {
    padding-top: 5rem;
    : 5rem; }
  .pr-xl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-xl-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-xl-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-xl-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-xl-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-xl-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-xl-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-xl-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-xl-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-xl-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-xl-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-xl-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-xl-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-xl-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-xl-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-xl-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-xl-16 {
    padding-right: 4rem;
    : 4rem; }
  .pr-xl-17 {
    padding-right: 4.25rem;
    : 4.25rem; }
  .pr-xl-18 {
    padding-right: 4.5rem;
    : 4.5rem; }
  .pr-xl-19 {
    padding-right: 4.75rem;
    : 4.75rem; }
  .pr-xl-20 {
    padding-right: 5rem;
    : 5rem; }
  .pb-xl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-xl-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-xl-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-xl-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-xl-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-xl-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-xl-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-xl-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-xl-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-xl-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-xl-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-xl-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-xl-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-xl-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-xl-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-xl-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-xl-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pb-xl-17 {
    padding-bottom: 4.25rem;
    : 4.25rem; }
  .pb-xl-18 {
    padding-bottom: 4.5rem;
    : 4.5rem; }
  .pb-xl-19 {
    padding-bottom: 4.75rem;
    : 4.75rem; }
  .pb-xl-20 {
    padding-bottom: 5rem;
    : 5rem; }
  .pl-xl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-xl-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-xl-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-xl-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-xl-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-xl-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-xl-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-xl-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-xl-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-xl-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-xl-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-xl-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-xl-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-xl-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-xl-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-xl-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-xl-16 {
    padding-left: 4rem;
    : 4rem; }
  .pl-xl-17 {
    padding-left: 4.25rem;
    : 4.25rem; }
  .pl-xl-18 {
    padding-left: 4.5rem;
    : 4.5rem; }
  .pl-xl-19 {
    padding-left: 4.75rem;
    : 4.75rem; }
  .pl-xl-20 {
    padding-left: 5rem;
    : 5rem; }
  .px-xl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-xl-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-xl-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-xl-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-xl-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-xl-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-xl-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-xl-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-xl-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-xl-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-xl-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-xl-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-xl-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-xl-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-xl-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-xl-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-xl-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .px-xl-17 {
    padding-left: 4.25rem;
    padding-right: 4.25rem; }
  .px-xl-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem; }
  .px-xl-19 {
    padding-left: 4.75rem;
    padding-right: 4.75rem; }
  .px-xl-20 {
    padding-left: 5rem;
    padding-right: 5rem; }
  .py-xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-xl-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-xl-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-xl-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-xl-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-xl-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-xl-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-xl-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-xl-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-xl-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-xl-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-xl-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-xl-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-xl-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-xl-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .py-xl-17 {
    padding-top: 4.25rem;
    padding-bottom: 4.25rem; }
  .py-xl-18 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem; }
  .py-xl-19 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem; }
  .py-xl-20 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .p-xl-0 {
    padding: 0rem;
    : 0rem; }
  .p-xl-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-xl-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-xl-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-xl-4 {
    padding: 1rem;
    : 1rem; }
  .p-xl-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-xl-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-xl-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-xl-8 {
    padding: 2rem;
    : 2rem; }
  .p-xl-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-xl-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-xl-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-xl-12 {
    padding: 3rem;
    : 3rem; }
  .p-xl-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-xl-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-xl-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-xl-16 {
    padding: 4rem;
    : 4rem; }
  .p-xl-17 {
    padding: 4.25rem;
    : 4.25rem; }
  .p-xl-18 {
    padding: 4.5rem;
    : 4.5rem; }
  .p-xl-19 {
    padding: 4.75rem;
    : 4.75rem; }
  .p-xl-20 {
    padding: 5rem;
    : 5rem; } }

@media (min-width: 1281px) {
  .mt-xl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-xl-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-xl-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-xl-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-xl-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-xl-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-xl-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-xl-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-xl-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-xl-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-xl-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-xl-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-xl-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-xl-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-xl-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-xl-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-xl-16 {
    margin-top: 4rem;
    : 4rem; }
  .mt-xl-17 {
    margin-top: 4.25rem;
    : 4.25rem; }
  .mt-xl-18 {
    margin-top: 4.5rem;
    : 4.5rem; }
  .mt-xl-19 {
    margin-top: 4.75rem;
    : 4.75rem; }
  .mt-xl-20 {
    margin-top: 5rem;
    : 5rem; }
  .mr-xl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-xl-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-xl-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-xl-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-xl-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-xl-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-xl-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-xl-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-xl-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-xl-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-xl-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-xl-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-xl-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-xl-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-xl-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-xl-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-xl-16 {
    margin-right: 4rem;
    : 4rem; }
  .mr-xl-17 {
    margin-right: 4.25rem;
    : 4.25rem; }
  .mr-xl-18 {
    margin-right: 4.5rem;
    : 4.5rem; }
  .mr-xl-19 {
    margin-right: 4.75rem;
    : 4.75rem; }
  .mr-xl-20 {
    margin-right: 5rem;
    : 5rem; }
  .mb-xl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-xl-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-xl-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-xl-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-xl-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-xl-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-xl-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-xl-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-xl-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-xl-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-xl-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-xl-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-xl-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-xl-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-xl-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-xl-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-xl-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .mb-xl-17 {
    margin-bottom: 4.25rem;
    : 4.25rem; }
  .mb-xl-18 {
    margin-bottom: 4.5rem;
    : 4.5rem; }
  .mb-xl-19 {
    margin-bottom: 4.75rem;
    : 4.75rem; }
  .mb-xl-20 {
    margin-bottom: 5rem;
    : 5rem; }
  .ml-xl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-xl-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-xl-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-xl-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-xl-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-xl-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-xl-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-xl-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-xl-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-xl-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-xl-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-xl-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-xl-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-xl-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-xl-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-xl-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-xl-16 {
    margin-left: 4rem;
    : 4rem; }
  .ml-xl-17 {
    margin-left: 4.25rem;
    : 4.25rem; }
  .ml-xl-18 {
    margin-left: 4.5rem;
    : 4.5rem; }
  .ml-xl-19 {
    margin-left: 4.75rem;
    : 4.75rem; }
  .ml-xl-20 {
    margin-left: 5rem;
    : 5rem; }
  .mx-xl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-xl-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-xl-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-xl-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-xl-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-xl-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-xl-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-xl-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-xl-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-xl-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-xl-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-xl-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-xl-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-xl-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-xl-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-xl-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-xl-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .mx-xl-17 {
    margin-left: 4.25rem;
    margin-right: 4.25rem; }
  .mx-xl-18 {
    margin-left: 4.5rem;
    margin-right: 4.5rem; }
  .mx-xl-19 {
    margin-left: 4.75rem;
    margin-right: 4.75rem; }
  .mx-xl-20 {
    margin-left: 5rem;
    margin-right: 5rem; }
  .my-xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-xl-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-xl-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-xl-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-xl-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-xl-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-xl-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-xl-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-xl-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-xl-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-xl-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-xl-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-xl-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-xl-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-xl-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .my-xl-17 {
    margin-top: 4.25rem;
    margin-bottom: 4.25rem; }
  .my-xl-18 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem; }
  .my-xl-19 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem; }
  .my-xl-20 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .m-xl-0 {
    margin: 0rem;
    : 0rem; }
  .m-xl-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-xl-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-xl-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-xl-4 {
    margin: 1rem;
    : 1rem; }
  .m-xl-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-xl-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-xl-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-xl-8 {
    margin: 2rem;
    : 2rem; }
  .m-xl-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-xl-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-xl-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-xl-12 {
    margin: 3rem;
    : 3rem; }
  .m-xl-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-xl-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-xl-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-xl-16 {
    margin: 4rem;
    : 4rem; }
  .m-xl-17 {
    margin: 4.25rem;
    : 4.25rem; }
  .m-xl-18 {
    margin: 4.5rem;
    : 4.5rem; }
  .m-xl-19 {
    margin: 4.75rem;
    : 4.75rem; }
  .m-xl-20 {
    margin: 5rem;
    : 5rem; } }

.section-title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.16667; }
  @media (max-width: 1024px) {
    .section-title {
      font-size: 2.25rem; } }

.heading-2 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.25; }

.other-title, .heading-3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.33333; }

.heading-4 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.4; }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-column {
  flex-direction: column; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-center {
  justify-content: center;
  align-items: center; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.items-center {
  align-items: center; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.items-baseline {
  align-items: baseline; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.hidden, .d-none, [hidden] {
  display: none; }

@media (max-width: 1024px) {
  .hidden-mobile {
    display: none; } }

@media (min-width: 1025px) {
  .hidden-desktop {
    display: none; } }

.text-transform-none {
  text-transform: none; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.font-light {
  font-weight: 300; }

.font-normal {
  font-weight: 400; }

.font-medium {
  font-weight: 500; }

.font-semibold {
  font-weight: 600; }

.font-bold, .text-bold {
  font-weight: 700; }

.font-italic {
  font-style: italic; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.position-fixed {
  position: fixed; }

.position-sticky {
  position: sticky; }

.top-0 {
  top: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.rounded {
  border-radius: 50%; }

.w-100 {
  width: 100%; }

.h-100 {
  width: 100%; }

.leading-tight {
  line-height: 1.25; }

.leading-snug {
  line-height: 1.333333; }

.leading-normal {
  line-height: 1.5; }

.leading-relaxed {
  line-height: 1.625; }

.leading-loose {
  line-height: 2; }

.overflow-hidden {
  overflow: hidden; }

.background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
