.loading-area
	width: 100%
	height: 100%
	position: fixed
	left: 0
	top: 0
	z-index: 9999
	overflow: hidden

.loading-box
	width: 100%
	height: 100%
	background-color: #FFF
	position: absolute
	left: 0
	top: 0
	opacity: 1
	z-index: 9999

.loading-pic
	width: 100%
	position: absolute
	top: 50%
	z-index: 99999
	text-align: center
	transform: translateY(-50%)

.loader
	height: 58px
	left: 50%
	position: absolute
	transform: translateX(-50%) translateY(-50%)
	width: 58px
	span
		background: #142d65
		display: block
		height: 12px
		opacity: 0
		position: absolute
		width: 12px
		animation: load 4.6s ease-in-out infinite

.loader span.block-1
	animation-delay: 1.06s
	left: 0px
	top: 0px

.loader span.block-2
	animation-delay: 0.97s
	left: 16px
	top: 0px

.loader span.block-3
	animation-delay: 0.87s
	left: 31px
	top: 0px

.loader span.block-4
	animation-delay: 0.78s
	left: 47px
	top: 0px

.loader span.block-5
	animation-delay: 0.69s
	left: 0px
	top: 16px

.loader span.block-6
	animation-delay: 0.6s
	left: 16px
	top: 16px

.loader span.block-7
	animation-delay: 0.51s
	left: 31px
	top: 16px

.loader span.block-8
	animation-delay: 0.41s
	left: 47px
	top: 16px

.loader span.block-9
	animation-delay: 0.32s
	left: 0px
	top: 31px

.loader span.block-10
	animation-delay: 0.23s
	left: 16px
	top: 31px

.loader span.block-11
	animation-delay: 0.14s
	left: 31px
	top: 31px

.loader span.block-12
	animation-delay: 0.05s
	left: 47px
	top: 31px

.loader span.block-13
	animation-delay: -0.05s
	left: 0px
	top: 47px

.loader span.block-14
	animation-delay: -0.14s
	left: 16px
	top: 47px

.loader span.block-15
	animation-delay: -0.23s
	left: 31px
	top: 47px

.loader span.block-16
	animation-delay: -0.32s
	left: 47px
	top: 47px

@keyframes load
	0%
		opacity: 0
		transform: translateY(-97px)
	15%
		opacity: 0
		transform: translateY(-97px)
	30%
		opacity: 1
		transform: translateY(0)
	70%
		opacity: 1
		transform: translateY(0)
	85%
		opacity: 0
		transform: translateY(97px)
	100%
		opacity: 0
		transform: translateY(97px)
