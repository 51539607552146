.global-footer
	padding: r(80px) 0 r(48px)
	background-image: url(../img/footer-bg.jpg)
	+text(white)
	+mq-max(lg)
		padding: r(40px) 0 r(40px)

.footer-link
	h3
		+font(700,18px,24px)
		margin-bottom: r(20px)
		text-transform: uppercase
	ul
		+flex-gap-col(r(8px))
		li
			a
				+fz-lh(16px,24px)


.footer-bottom
	margin-top: r(48px)
	padding-top: r(48px)
	border-top: 1px solid rgba(#fff , 0.1)
	+mq-max(lg)
		margin-top: r(32px)
		padding-top: r(32px)

.footer-info
	h3 
		+font(700, 32px, 48px)
		margin-bottom: r(24px)
	ul
		+flex-gap-col(r(16px))
		li
			display: flex
			align-items: center
			+flex-gap(r(16px))
			span
				+circle(r(44px))
				+flex-center
				display: flex
				+bg(white)
				+text(main)
				font-size: r(20px)
			p
				flex: 1
				+fz-lh(16px, 24px)

.footer-copyright
	+fz-lh(14px,18px)
	+flex-gap-col(r(12px))

.footer-subscription
	.wrap-form
		h3 
			+font(600, 24px, 32px)
			margin-bottom: 1rem
		p 
			+fz-lh(16px, 24px)
		.form-note
			margin-bottom: r(20px)
		.form-group
			textarea
				height: r(96px)
			input[type="text"], input[type="date"], select, textarea
				ckground: #fff
		.frm-btn
			input[type="submit"]
				+bg(blue)