.about-nav-wrap
	+bg(white)
.about-nav
	margin-top: r(40px)
	+mq-max(lg)
		margin-top: r(20px)
	ul
		+flex-gap(r(40px))
		padding-bottom: r(12px)
		border-bottom: 1px solid color(grey-ccc)
		+mq-min(lg)
			justify-content: center
		+mq-max(lg)
			white-space: nowrap
			overflow-x: auto
		li
			a
				+fz-lh(14px,20px)
				position: relative
				display: inline-flex
				+trans-all
				+parent-state(":hover",".active")
					+text(main)
					&:before
						opacity: 1
				&:before
					+pseudo
					+trans-all
					bottom: r(-13px)
					height: 1px
					width: 100%
					+bg(main)
					opacity: 0

.about-section-1
	position: relative
	z-index: 1
	+mq-min(lg)
		overflow: hidden
		&:before
			+pseudo
			aspect-ratio: 168 / 147
			content: url(../img/about-bg-1.png)
			left: 0
			bottom: -420px
			z-index: -1
	.img-wrap
		+mq-min(lg)
			.img
				figure
					height: r(553px)
					img
						+fit-cover
						object-position: left
	.content-wrap
		+mq-min(lg)
			position: absolute
			left: 0
			width: 100%
			top: r(60px)
		+mq-max(lg)
			margin-bottom: r(20px)

.about-section-2
	position: relative
	z-index: 1
	+mq-max(lg)
		padding: r(40px) 0 0
	+mq-min(lg)
		overflow: hidden
		&:before
			+pseudo
			aspect-ratio: 168 / 147
			content: url(../img/about-bg-2.png)
			left: 0
			bottom: 56px
			z-index: -1
	.content-wrap
		+mq-min(lg)
			position: absolute
			left: 0
			+center(Y)
			width: 100%
		+mq-max(lg)
			margin-bottom: r(48px)
	.img-wrap
		+mq-min(lg)
			.img
				figure
					height: r(583px)
					img
						+fit-cover
						object-position: left
	.list-item
		margin-top: r(48px)
		padding-left: r(24px)
		display: flex
		flex-wrap: wrap
		margin-left: r(-64px)
		> *
			padding-left: r(64px)
		+mq-max(lg)
			margin-left: r(-96px)
			justify-content: center
		.title
			position: relative
			border: 1px solid #FFFFFF
			border-radius: 10px
			transform: rotate(45deg)
			+box(r(118px))
			display: flex
			+flex-center
			span
				display: block
				transform: rotate(-45deg)
				position: absolute
				text-align: center
				+fz-lh(18px,24px) 

.about-section-3
	.section-content
		+mq-min(lg)
			height: r(592px)
			display: flex
			> *
				width: 100%
				.row
					height: 100%
	.item-wrap
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		height: 100%
		+bg(main)
		+text(white)
		+mq-min(lg)
			transform: skew(-10deg)
		.item
			text-align: center
			padding: 0 r(60px)
			+mq-min(lg)
				transform: skew(10deg)
			+mq-max(lg)
				padding: r(30px) r(24px)
			.icon
				margin-bottom: r(28px)
			.title
				text-transform: uppercase
				+font(700,18px,24px)
				margin-bottom: r(12px)
	.item-col
		+after-first(1)
			+mq-max(lg)
				margin-top: r(20px)
			+mq-min(lg)
				.item-wrap
					margin-left: r(-5px)
		+each(2)
			+mq-min(lg)
				padding-top: r(60px)
			.item-wrap
				+bg-opa(blue, 0.8)
		+each(3)
			+mq-min(lg)
				padding-top: r(120px)
			.item-wrap
				+bg(white)
				+text(body)
				.item
					.title
						+text(main)

.staff-list-wrap
	+after-first(1)
		margin-top: r(40px)

.staff-list
	.staff-item
		.img
			position: relative
			z-index: 1
			.bg
				position: absolute
				top: 7.5%
				left: 0
				z-index: -1
				opacity: 0.18
			figure
				aspect-ratio: 2 / 3
				img
					+fit-contain
					object-position: bottom
		.caption
			text-align: center
			+flex-gap-col(r(8px))
			padding: r(12px) r(32px)
			+bg(white)
			+text(main)
			line-height: 1.5
			+mq-max(md)
				padding: r(12px) r(16px)

.about-section-5
	position: relative
	.bg-left
		position: absolute
		top: 0
		left: 0
	.bg-right
		position: absolute
		bottom: 0
		right: 0
	.bg-item
		z-index: -1
		img
			width: 10vw

.about-section-6
	+mq-min(lg)
		min-height: r(429px)

.customer-slider
	.swiper-container
		padding: r(10px) 0
		margin: r(-10px) 0

.partner-item
	+trans-all
	+on-hover
		transform: translateY(-10px)
	figure
		aspect-ratio: 230 / 140
		+bg(white)
		display: flex
		+flex-center
		img
			width: 90%

.about-section-7
	+mq-min(lg)
		min-height: r(725px)
		display: flex
		flex-direction: column
	.customer-slider-wrap
		padding: 0 0 r(40px)
		+mq-min(lg)
			height: r(570px)
			padding: r(120px) 0
			+bg-img-cover("../img/about-bg-8.png")

.award-item
	.img
		figure
			aspect-ratio: 285 / 407
			img
				+fit-contain
	.title
		+fz-lh(14px,20px)
		margin-top: 0.5rem
		text-align: center

.about-section-9
	+mq-min(lg)
		min-height: r(924px)
		position: relative
	.img-wrap
		margin-top: r(20px)
		+mq-min(lg)
			margin-top: 0
			position: absolute
			top: r(20px)
			right: 0
			width: 1021 / 1917 * 100%
	.history-slider
		+mq-min(lg)
			position: relative
			&:before
				+pseudo
				left: r(128px)
				top: 0
				bottom: 0
				width: 1px
				+bg(main)
			.swiper-container
				height: r(580px)
		.swiper-vertical-btn
			position: absolute
			left: r(28px)
			font-size: r(32px)
			+text(grey-ccc)
			cursor: pointer
			+remove(lg,max)
			&.swiper-prev
				bottom: 100%
			&.swiper-next
				top: 100%
		.history-item
			display: flex
			padding: r(16px) 0 r(30px)
			+mq-max(lg)
				flex-direction: column
				padding: 0 r(24px) 0 0
			.year
				+font(700,40px,48px)
				+text(main)
				+mq-min(lg)
					+flex-width(r(114px))
				+mq-max(lg)
					font-size: r(28px)
			.content-wrap
				padding-left: r(40px)
				+flex-gap(r(20px))
				+mq-max(lg)
					padding-left: 0
				.fas
					+text(main)
					font-size: r(20px)
					line-height: r(28px)
					position: relative
					top: r(8px)
					+remove(lg,max)