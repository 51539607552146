.form-group
	input[type="text"], input[type="date"], select, textarea
		+radius(r(4px))
		background: #F5F5F5
		border: 0
		padding: r(12px) r(20px) !important
		width: 100%
		height: r(48px)
		font-style: normal !important
		font-size: 1rem !important
		font-family: 'Plus Jakarta Sans', sans-serif !important
	select
		appearance: none
		+select-bg($pos: calc(100% - 1.25rem))
		option
			+text(body)
	textarea
		height: r(200px)
	.RadPicker
		width: 100% !important
		position: relative
		.riTextBox
			+bg-opa(white, 0.95)
			border: 0 !important
		.riEmpty
			+text(grey-666)
		.rcTable
			width: 100% !important
		.rcCalPopup
			position: absolute
			right: r(10px)
			+center(Y)
			background-image: url(../img/calendar.png)
			background-position: center !important
	&.qradio
		ul
			margin-bottom: r(-20px)
			+columnizer(2,r(20px))
			li
				input
					+hide()
					&:checked ~ label
						color: color(blue)
						&:before
							content: '\f192'
				label
					display: flex
					&:before
						content: '\f111'
						font-family: "Font Awesome 6 Pro"
						margin-right: r(10px)
						position: relative

.frm-btnwrap
	margin-top: r(30px)
	+mq-max(sm)
		margin-top: r(20px)

.frm-btn
	position: relative
	display: inline-block
	margin-left: auto
	width: r(116px)
	&:before
		+center(Y)
		right: r(24px)
		content: '\f178'
		font-family: "Font Awesome 6 Pro"
		+text(white)
		pointer-events: none
	input[type="submit"]
		+trans-all
		+radius(r(24px))
		+bg(main)
		+text(white)
		border: 0
		height: r(48px)
		font-weight: 700
		width: 100%
		cursor: pointer
		padding: 0 r(64px) 0 r(30px)
		font-size: 1rem
		text-transform: uppercase
		appearance: none

.frm-captcha
	display: flex
	flex-wrap: wrap-reverse
	margin-top: r(20px)
	flex-direction: row-reverse
	justify-content: flex-end
	gap: r(20px)
	label
		display: none
	.frm-captcha-input
		flex: 1
		+mq-max(sm)
			flex: 0 0 100%
	.RadCaptcha
		display: flex
		flex-direction: column-reverse
		flex: 1
		max-width: fit-content
		img
			height: r(50px) !important
		> span
			color: red
			display: block
			margin-top: 5px
		> div
			> div
				display: flex
				align-items: center
		a
			font-size: 0
			margin-left: 20px
			// display: none !important
			&:before
				font-family: "Font Awesome 6 Pro"
				content: '\f2ea'
				font-size: r(27px)
				color: #333

.form-container
	.wrap-form
		.qradio
			.label
				display: none
		.frm-btn
			height: r(50px)
			width: 100%
			input[type="submit"]
				width: 100%
				min-width: auto
		.frm-btnwrap
			width: r(115px)
			margin-top: 0 !important
			+mq-max(sm)
				width: 100%
