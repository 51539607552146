.home-section-1
	+text(white)
	+mq-min(lg)
		min-height: r(1073px)
		position: relative
	.img-wrap
		+mq-min(lg)
			position: absolute
			top: 0
			right: 0
			width: 1053 / 1917 * 100%
	.content-wrap
		+mq-min(lg)
			min-height: r(728px)
	.list-wrap
		.item
			text-align: center
			.icon
				aspect-ratio: 1
				width: r(56px)
				margin: 0 auto r(12px)
				img
					+fit-contain
			.number
				+font(700,56px,76px)
			.text
				margin-top: 0.5rem
				line-height: 1.5