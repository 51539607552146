.sub-banner
    overflow: hidden
    .banner
        position: relative
        .caption
            position: absolute
            bottom: r(80px)
            left: 0
            width: 100%
            +text(white)
            +fz-lh(40px, 52px)
            +mq-max(lg)
                bottom: r(24px)
                +fz-lh(20px,28px)
        .img
            figure
                +mq-max(lg)
                    aspect-ratio: 16 / 9
                    img
                        +fit-cover

.news-detail-page,.product-detail-page,.recruitment-detail-page
    .sub-banner
        display: none