.field-detail-feature
	figure
		aspect-ratio: 390 / 240
		img
			+fit-cover

.field-detail-feature-slider
	overflow: hidden
	.swiper-container
		overflow: visible
	.swiper-slide
		.field-detail-project
			position: relative
			&:before
				+mq-min(lg)
					+pseudo
					bottom: 0
					background: linear-gradient(180deg, rgba(4, 5, 11, 0) 35.71%, rgba(35, 48, 100, 0.74) 70%)
					width: 100%
					left: 0
					height: r(248px)
					opacity: 0
					transition: 0.3s all
					pointer-events: none
					+context(".swiper-slide",".swiper-slide-active")
						opacity: 1
			.img
				figure
					aspect-ratio: 1230 / 600
					img
						+fit-cover
			.caption
				text-align: center
				padding: 0 1rem 2rem
				text-transform: uppercase
				+font(700,20px,28px)
				opacity: 0
				transition: 0.3s all
				+mq-max(lg)
					margin-top: r(16px)
					font-size: r(14px)
				+mq-min(lg)
					position: absolute
					width: 100%
					bottom: 0
					+text(white)
					pointer-events: none
				+context(".swiper-slide",".swiper-slide-active")
					opacity: 1
