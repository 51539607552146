.shareholder-list-wrap
	+even
		+bg(grey-light)
		.row
			flex-direction: row-reverse
	
.report-table-wrap
	border-radius: r(8px)
	overflow: hidden
	
.report-table
	table
		width: 100%
		+fz-lh(16px,24px)
		+bg(white)
		th,td
			padding: r(12px) r(20px)
		th
			+bg(main)
			+text(white)
			text-transform: uppercase
			white-space: nowrap
			height: r(50px)
		tr
			&:hover
				color: color(main) !important
			+even
				+bg(grey-f5)
				
		tfoot
			td
				height: r(48px)
				a
					&:hover
						color: color(main) !important

.report-img
	figure
		height: 100%
		img
			+fit-cover

.company-info
	+bg(main)
	+text(white)
	.img
		figure
			aspect-ratio: 390 / 266
			img
				+fit-cover
	.info
		+flex-gap-col(1rem)
		padding: r(24px) r(32px)
		h3
			+font(700,24px,32px)
		p
			+fz-lh(18px,24px)
			+line(3)