.global-header
	box-shadow: $shadow-sp
	+bg(white)

.header-wrap
	display: flex
	justify-content: space-between
	align-items: center
	+mq-max(lg)
		height: r(64px)

.logo
	+mq-max(lg)
		height: r(60px)
		img
			height: r(60px)

.header-right
	+flex-gap(r(28px))
	align-items: center

.header-util-wrapper
	+flex-gap(r(12px))
	align-items: center

.menu
	> ul
		+mq-min(lg)
			+flex-gap(r(28px))
		> li
			+mq-max(lg)
				+after-first(1)
					margin-top: 6vw
			> a
				display: inline-flex
				min-height: r(88px)
				align-items: center
				+font(700, 18px, 24px)
				position: relative
				+context('li','li.active')
					+mq-min(lg)
						+text(main)
				&:before
					+pseudo
					height: r(4px)
					width: 100%
					+radius-top(r(4px))
					bottom: 0
					left: 0
					display: none
					+bg(white)
					+mq-min(lg)
						+bg(main)
					+context('li','li.active')
						display: block
				+mq-max(lg)
					font-size: 6.4vw
					min-height: auto
					padding-bottom: 3.6vw
					text-align: left
					justify-content: flex-start
					+text(white)

.has-sub
	position: relative
	display: flex
	align-items: center
	> span
		margin-left: 5px
	.sub-menu
		display: none
		position: absolute
		top: 100%
		left: 0
		z-index: 2
		+mq-min(lg)
			min-width: r(240px)
		ul
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15)
			background: #fff
			li
				a
					white-space: nowrap
					display: flex
					align-items: center
					font-size: 1rem
					padding: r(12px) r(24px)
					+trans-all
				&:hover,&.active
					a
						+bg(main)
						+text(white)
	+mq-max(lg)
		justify-content: space-between
		flex-wrap: wrap
		> span
			font-size: 4vw
			+text(white)
			position: relative
			top: -1vw
		.sub-menu
			position: static
			width: 100%
			border-top: 0
			box-shadow: none
			ul
				background: transparent
				background: #fff
				color: #333
				li
					a
						font-size: 2.4vw
						white-space: initial
						padding: 2vw 3.6vw
					&.active
						a
							margin-right: 0
							border-radius: 0
	+mq-min(lg)
		.toggle
			display: none
		&:hover
			.sub-menu
				display: block

.search-link
	a
		display: flex
		+flex-center
		+font(700, 18px, 24px)
		+text(main)

.header-util-divider
	height: r(20px)
	width: 1px
	+bg(grey-light)

.language
	.current
		display: flex
		+flex-center
		+fz-lh(16px, 24px)
		em
			margin-left: r(8px)
			+fz-lh(14px, 16px)
	.dropdown-content
		z-index: 2
		top: 100%
		min-width: auto
		ul
			padding: r(8px) r(16px)
			+fz-lh(14px, 20px)

.menu-toggle
	display: none
	+mq-max(lg)
		display: flex
		+flex-center
		+box(r(48px), r(48px))
		font-size: r(24px)
		line-height: 1
		+bg(main)
		+text(white)

.mobile-menu
	position: fixed
	width: 100vw
	height: 100vh
	z-index: 100
	display: none
	top: 0
	left: 0
	background: rgba(#fff , 0.1)
	backdrop-filter: blur(5px)
	.mobile-menu-overlay
		position: absolute
		z-index: 1
		height: 100%
		width: 100%
	.mobile-menu-wrapper
		z-index: 2
		height: 100%
		width: 100%
		position: relative
		transform: translateY(-100%)
		transition: 0.3s all
		pointer-events: none
		+parent-state(".is-open")
			transform: none
	.mobile-menu-container
		position: absolute
		pointer-events: all
		+center(both)
		height: 88vh
		width: 94vw
		background: linear-gradient(180deg, rgba(33,18,97,1) 50%, rgba(46,20,147,1) 100%)
		padding: 8vw 6vw
		overflow: auto
	.logo-w
		margin-bottom: 8vw