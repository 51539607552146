.home-banner-slider
	.swiper-pagination
		bottom: r(60px)
		+mq-max(lg)
			bottom: r(20px)
		.swiper-pagination-bullet
			border: 1px solid color(white)
			opacity: 1
			background: transparent
			width: r(10px)
			height: r(10px)
			border-radius: 10px
			transition: 0.3s all
			&.swiper-pagination-bullet-active
				+bg(white)
				width: r(20px)
	.banner-item
		position: relative
		.banner-caption
			position: absolute
			left: 0
			width: 100%
			bottom: r(140px)
			color: #fff
			font-size: r(40px)
			line-height: 1.333333
			font-weight: 300
			+mq-max(lg)
				bottom: r(24px)
				font-size: r(24px)
		.banner-img
			+mq-max(md)
				figure
					+img-scale(133333 / 100000 * 100%)