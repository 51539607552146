.contact-info
	padding: r(52px) r(36px)
	+mq-max(lg)
		padding: r(24px)
	+bg(main)
	+text(white)
	border-radius: r(12px)
	h2
		+font(700,32px,44px)
		margin-bottom: r(24px)
	img
		width: 100%
		margin-bottom: r(24px)
	ul
		+flex-gap-col(r(16px))
		li
			display: flex
			align-items: center
			+flex-gap(r(16px))
			+after-first(1)
				padding-top: 1rem
				border-top: 1px solid rgba(#fff , 0.2)
			span
				+circle(r(44px))
				+flex-center
				display: flex
				+bg(white)
				+text(main)
				font-size: r(20px)
			p
				flex: 1
				+fz-lh(16px, 24px)

.contact-form
	+mq-min(lg)
		padding: r(36px) 0
	+mq-max(lg)
		margin-top: r(30px)
		border-radius: r(8px)
		overflow: hidden
	.form-title
		padding: r(12px) r(40px) r(12px) r(60px)
		+bg(main)
		+text(white)
		+font(700,20px,28px)
		text-transform: uppercase
		+mq-max(lg)
			padding: r(12px) r(24px)
		+mq-min(lg)
			border-radius: 0px r(8px) 0 0px
	.wrap-form
		border-right: 1px solid #CCCCCC
		border-bottom: 1px solid #CCCCCC
		overflow: hidden
		padding: r(24px) r(40px) r(24px) r(60px)
		+mq-min(lg)
			border-radius: 0px 0 r(8px) 0px
		+mq-max(lg)
			border-radius: 0px 0 r(8px) r(8px)
			padding: r(16px) r(24px)
			border: 1px solid #CCCCCC
		.row
			+gap-override(20px)
		
.contact-map
	iframe
		height: r(600px)
		width: 100%